export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const LOGIN_MODAL_OPEN = 'LOGIN_MODAL_OPEN';
export const LOGIN_MODAL_CLOSE = 'LOGIN_MODAL_CLOSE';
export const SIGNUP_MODAL_OPEN = 'SIGNUP_MODAL_OPEN';
export const SIGNUP_MODAL_CLOSE = 'SIGNUP_MODAL_CLOSE';
export const RESET_PASSWORD_MODAL_OPEN = 'RESET_PASSWORD_MODAL_OPEN';
export const RESET_PASSWORD_MODAL_CLOSE = 'RESET_PASSWORD_MODAL_CLOSE';
export const POST_STUDENT_LOGIN = 'POST_STUDENT_LOGIN';
export const POST_STUDENT_LOGIN_SUCCESS = 'POST_STUDENT_LOGIN_SUCCESS';
export const POST_STUDENT_LOGIN_FAILURE = 'POST_STUDENT_LOGIN_FAILURE';
export const POST_TEACHER_LOGIN = 'POST_TEACHER_LOGIN';
export const POST_TEACHER_LOGIN_SUCCESS = 'POST_TEACHER_LOGIN_SUCCESS';
export const POST_TEACHER_LOGIN_FAILURE = 'POST_TEACHER_LOGIN_FAILURE';
export const POST_STUDENT_SIGN_UP = 'POST_STUDENT_SIGN_UP';
export const POST_STUDENT_SIGN_UP_SUCCESS = 'POST_STUDENT_SIGN_UP_SUCCESS';
export const POST_STUDENT_SIGN_UP_FAILURE = 'POST_STUDENT_SIGN_UP_FAILURE';
export const POST_TEACHER_SIGN_UP = 'POST_TEACHER_SIGN_UP';
export const POST_TEACHER_SIGN_UP_SUCCESS = 'POST_TEACHER_SIGN_UP_SUCCESS';
export const POST_TEACHER_SIGN_UP_FAILURE = 'POST_TEACHER_SIGN_UP_FAILURE';
export const POST_TEACHER_PASSWORD_RESET = 'POST_TEACHER_PASSWORD_RESET';
export const POST_TEACHER_PASSWORD_RESET_SUCCESS = 'POST_TEACHER_PASSWORD_RESET_SUCCESS';
export const POST_TEACHER_PASSWORD_RESET_FAILURE = 'POST_TEACHER_PASSWORD_RESET_FAILURE';
export const POST_STUDENT_PASSWORD_RESET = 'POST_STUDENT_PASSWORD_RESET';
export const POST_STUDENT_PASSWORD_RESET_SUCCESS = 'POST_STUDENT_PASSWORD_RESET_SUCCESS';
export const POST_STUDENT_PASSWORD_RESET_FAILURE = 'POST_STUDENT_PASSWORD_RESET_FAILURE';
export const GET_STUDENT_TAKEN_EXAM = 'GET_STUDENT_TAKEN_EXAM';
export const GET_STUDENT_TAKEN_EXAM_SUCCESS = 'GET_STUDENT_TAKEN_EXAM_SUCCESS';
export const UPDATE_STUDENT_TAKEN_EXAM = 'UPDATE_STUDENT_TAKEN_EXAM';
export const FORGOT_PASSWORD_OPEN = 'FORGOT_PASSWORD_OPEN';
export const FORGOT_PASSWORD_CLOSE = 'FORGOT_PASSWORD_CLOSE';
export const POST_TENANT_FORGOT_PASSWORD = 'POST_TENANT_FORGOT_PASSWORD';
export const POST_FORGOT_PASSWORD_SUCCESS = 'POST_FORGOT_PASSWORD_SUCCESS';
export const POST_STUDENT_FORGOT_PASSWORD = 'POST_STUDENT_FORGOT_PASSWORD';
export const POST_STUDENT_FORGOT_PASSWORD_SUCCESS = 'POST_FORGOT_PASSWORD_SUCCESS';
export const POST_TENANT_FORGOT_PASSWORD_VERIFY = 'POST_TENANT_FORGOT_PASSWORD_VERIFY';
export const POST_STUDENT_FORGOT_PASSWORD_VERIFY = 'POST_STUDENT_FORGOT_PASSWORD_VERIFY';
export const POST_FORGOT_PASSWORD_SUCCESS_VERIFY = 'POST_FORGOT_PASSWORD_SUCCESS_VERIFY';
export const POST_STUDENT_FORGOT_PASSWORD_SUCCESS_VERIFY = 'POST_STUDENT_FORGOT_PASSWORD_SUCCESS_VERIFY';
export const GET_STUDENT_EXAM_RESULT_ANALYSIS = 'GET_STUDENT_EXAM_RESULT_ANALYSIS';
export const GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS = 'GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS';
export const GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE = 'GET_STUDENT_EXAM_RESULT_ANALYSIS_FAILURE';
export const GET_ALL_META_DATA = 'GET_ALL_META_DATA';
export const GET_ALL_META_DATA_SUCCESS = 'GET_ALL_META_DATA_SUCCESS';
export const GET_ALL_META_DATA_FAILURE = 'GET_ALL_META_DATA_FAILURE';
