import { Avatar, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStyle } from '../../styles/style';
const MyAccount = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        if (!user.loggedIn) {
            navigate('/', { replace: true });
        }
    }, [user]);
    return (React.createElement("div", { className: classes.myAccount },
        React.createElement("div", { className: classes.myAccountSidePanel },
            React.createElement(Avatar, { sx: { width: 160, height: 160 } })),
        React.createElement("div", { className: classes.myAccountMain },
            React.createElement(Typography, { variant: "h4", className: classes.myAccountTitle }, "Public profile"),
            React.createElement(Stack, { spacing: 2, direction: "column", className: classes.myAccountFields },
                React.createElement(TextField, { variant: "outlined", size: "small", value: user.firstName, fullWidth: true, label: "First Name" }),
                React.createElement(TextField, { variant: "outlined", size: "small", value: user.middleName, fullWidth: true, label: "Middle Name" }),
                React.createElement(TextField, { variant: "outlined", size: "small", value: user.lastName, fullWidth: true, label: "Last Name" }),
                React.createElement(TextField, { variant: "outlined", size: "small", value: user.mobileNumber, fullWidth: true, label: "Phone Number" }),
                React.createElement(TextField, { variant: "outlined", size: "small", fullWidth: true, label: "Email", value: user.emailId }),
                React.createElement(TextField, { variant: "outlined", size: "small", fullWidth: true, label: "Address", value: user.address }),
                React.createElement(Button, { variant: "contained" }, "Save")))));
};
export default MyAccount;
