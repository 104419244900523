/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import HTMLString from 'react-html-string';
import { useStyle } from '../../styles/style';
import { EXAM_PREVIEW } from '../../containers/constant/constants';
const ExamBody = ({ activeQuestion, question, answered, examSetID, noOfQuestionsPerSet, handlePreviousClick, questionMarkedForReview, handleChoiceClick, handleReviewClick, handleNextClick, type, }) => {
    var _a;
    const classes = useStyle();
    return (React.createElement("div", { className: classes.questionBody },
        React.createElement("div", { className: classes.questionBodyHeader },
            React.createElement("div", null,
                "Question No. ",
                activeQuestion),
            React.createElement("div", { className: classes.marksDiv },
                React.createElement("div", null,
                    React.createElement("span", { className: classes.textGrey }, "Maximum Marks: "),
                    React.createElement("span", { className: classes.textGreen }, question === null || question === void 0 ? void 0 : question.correctMark)),
                React.createElement("div", null,
                    React.createElement("span", { className: classes.textGrey }, "Negative Marks: "),
                    React.createElement("span", { className: classes.textRed }, question === null || question === void 0 ? void 0 : question.negativeMark)))),
        React.createElement("div", { className: classes.questionBodyMain },
            React.createElement("div", null,
                React.createElement(HTMLString, { html: question === null || question === void 0 ? void 0 : question.questionDescription })),
            React.createElement("div", { className: classes.questionChoices }, (_a = question === null || question === void 0 ? void 0 : question.choices) === null || _a === void 0 ? void 0 : _a.map((choice, index) => {
                var _a;
                const answeredQuestion = (_a = answered === null || answered === void 0 ? void 0 : answered[examSetID]) === null || _a === void 0 ? void 0 : _a[question.questionID];
                return (React.createElement("div", { key: choice.choiceID, className: answeredQuestion && answeredQuestion === choice.choiceID
                        ? clsx(classes.questionChoice, classes.questionChoiceSelected)
                        : classes.questionChoice, onClick: () => handleChoiceClick({ choiceID: choice.choiceID }) },
                    React.createElement("div", { className: classes.choiceChar }, String.fromCharCode(65 + index)),
                    React.createElement(HTMLString, { html: choice.choice })));
            }))),
        React.createElement("div", { className: classes.questionButtons },
            React.createElement(Button, { disableElevation: true, variant: "contained", onClick: handlePreviousClick, disabled: activeQuestion === 1, startIcon: React.createElement(ArrowBackIos, null) }, "PREVIOUS"),
            React.createElement(Stack, { spacing: 2, direction: "row" },
                type === EXAM_PREVIEW.EXAM && (React.createElement(Button, { disableElevation: true, variant: questionMarkedForReview ? 'contained' : 'outlined', onClick: handleReviewClick, color: questionMarkedForReview ? 'warning' : 'primary' }, questionMarkedForReview
                    ? 'Marked for review'
                    : 'Mark for review')),
                React.createElement(Button, { disableElevation: true, variant: "contained", onClick: handleNextClick, endIcon: React.createElement(ArrowForwardIos, null), disabled: activeQuestion === noOfQuestionsPerSet }, "NEXT")))));
};
export default ExamBody;
