export const MARK_QUESTION_FOR_REVIEW = 'MARK_QUESTION_FOR_REVIEW';
export const UNMARK_QUESTION_FOR_REVIEW = 'UNMARK_QUESTION_FOR_REVIEW';
export const FULL_SCREEN_EXIT_COUNT = 'FULL_SCREEN_EXIT_COUNT';
export const POST_EXAM_START = 'POST_EXAM_START';
export const POST_EXAM_START_SUCCESS = 'POST_EXAM_START_SUCCESS';
export const POST_EXAM_START_FAILURE = 'POST_EXAM_START_FAILURE';
export const POST_EXAM_SUBMIT = 'POST_EXAM_SUBMIT';
export const POST_EXAM_SUBMIT_SUCCESS = 'POST_EXAM_SUBMIT_SUCCESS';
export const POST_EXAM_EVERY_CHOICE_CLICK = 'POST_EXAM_EVERY_CHOICE_CLICK';
export const POST_EXAM_AUDIT = 'POST_EXAM_AUDIT';
export const GET_STUDENT_EXAM_RESULT = 'GET_STUDENT_EXAM_RESULT';
export const GET_STUDENT_EXAM_RESULT_SUCCESS = 'GET_STUDENT_EXAM_RESULT_SUCCESS';
export const GET_STUDENT_EXAM_RESULT_FAILURE = 'GET_STUDENT_EXAM_RESULT_FAILURE';
export const GET_STUDENT_EXAM_QUESTION_CHOICE = 'GET_STUDENT_EXAM_QUESTION_CHOICE';
export const GET_STUDENT_EXAM_QUESTION_CHOICE_SUCCESS = 'GET_STUDENT_EXAM_QUESTION_CHOICE_SUCCESS';
export const GET_EXAMS_DETAILS_ON_START = 'GET_EXAMS_DETAILS_ON_START';
export const POST_BUY_EXAM = 'POST_BUY_EXAM';
export const POST_BUY_EXAM_SUCCESS = 'POST_BUY_EXAM_SUCCESS';
