import React, { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { EXAM_TYPE } from '../../containers/constant/constants';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import ExamQuestion from './ExamQuestion';
import TnC from './TnC';
const MainExam = () => {
    var _a;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { examID, examSetID, type, transactionID } = location.state;
    const handle = useFullScreenHandle();
    const { enter, exit } = handle;
    const classes = useStyle();
    const [tncSuccess, setTncSuccess] = useState(false);
    const exam = (_a = useSelector((state) => state.exam.exams)) === null || _a === void 0 ? void 0 : _a[examID];
    useEffect(() => {
        if (!examID)
            navigate('/');
    }, []);
    useEffect(() => {
        if (type === EXAM_TYPE.CONTINUE_EXAM)
            dispatch(Actions.getStudentExamQuestionChoice({
                studentExamID: transactionID,
                examSetID,
            }));
        dispatch(Actions.getQuestionPageLoad({
            params: { examID, examSetID },
        }));
    }, []);
    const handleFullScreenExit = () => {
        dispatch(Actions.fullScreenExitCount());
    };
    return (React.createElement(FullScreen, { handle: handle, className: tncSuccess ? classes.fullScreenGrey : classes.fullScreen, onChange: handleFullScreenExit }, !tncSuccess ? (React.createElement(TnC, { setTncSuccess: setTncSuccess, enter: enter, examSetID: examSetID, exam: exam, type: type })) : (React.createElement(ExamQuestion, { examID: examID, examSetID: examSetID, fullScreenExit: exit }))));
};
export default MainExam;
