import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../containers/images/startMyExam.png';
import { useStyle } from '../styles/style';
const Footer = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    return (React.createElement("div", { className: classes.footer },
        React.createElement("div", { className: classes.footerContent },
            React.createElement(Typography, { className: classes.typo, onClick: () => navigate('/contactUs') }, "Contact Us"),
            React.createElement(Typography, { className: classes.typo, onClick: () => navigate('/contactUs') }, "Help and Support"),
            React.createElement(Typography, { className: classes.typo, onClick: () => navigate('/Term-Condition.html') }, "Terms n Conditions"),
            React.createElement(Typography, { className: classes.typo, onClick: () => navigate('/Privacy-Policy.html') }, "Privacy Policy")),
        React.createElement("div", { className: classes.divider }),
        React.createElement("div", { className: classes.footerLogo },
            React.createElement("img", { src: Logo, alt: "logo", style: { width: '240px' } }),
            React.createElement("div", null,
                React.createElement(Typography, { className: classes.typo }, "Copyright 2024 Zonatech Digital Private Limited"),
                React.createElement(Typography, { className: classes.typo }, "Mail:info@zonatechdigital.com")))));
};
export default Footer;
