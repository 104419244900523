import { Typography } from '@mui/material';
import React from 'react';
import { PRIMARY_COLOR } from '../../styles/colors';
import { useStyle } from '../../styles/style';
import { SliderImages } from '../LandingPage/SliderImages';
const Box = (props) => {
    const classes = useStyle();
    const { exam } = props;
    return (React.createElement("div", { className: classes.trendingExamBox },
        React.createElement("img", { src: SliderImages[4], alt: "trending", className: classes.trendingExamImage }),
        React.createElement("div", { className: classes.trendingExamBody },
            React.createElement(Typography, { variant: "h3", className: classes.blue, sx: {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                } }, exam.examName),
            React.createElement(Typography, { style: { color: PRIMARY_COLOR.GREY150 } },
                "Questions in each Sets: ",
                exam.noOfQuestionsPerSet),
            React.createElement(Typography, { style: { color: PRIMARY_COLOR.GREY150 } },
                "Total Sets: ",
                exam.noOfSets),
            React.createElement(Typography, { variant: "h6", style: { color: PRIMARY_COLOR.GREY150 } },
                "\u20B9",
                exam.totalExamPrice))));
};
export default Box;
