import React from 'react';
import { Outlet } from 'react-router-dom';
import { useStyle } from '../styles/style';
import Footer from './Footer';
import Header from './Header';
import Modal from './Modal';
const MainComponent = () => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.main },
        React.createElement(Header, null),
        React.createElement(Outlet, null),
        React.createElement(Footer, null),
        React.createElement(Modal, null)));
};
export default MainComponent;
