import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, TextField, Typography, } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StudentLoginIcon from '../../UIElements/StudentLoginIcon';
import TeacherLoginIcon from '../../UIElements/TeacherLoginIcon';
import { PORTALS } from '../../containers/constant/constants';
import { forgotPasswordModalClose, loginModalOpen, postStudentForgotPassword, postTenantForgotPassword, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const ForgotPassword = () => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const [activePortal, setActivePortal] = useState(PORTALS.STUDENT_PORTAL);
    const [emailID, setEmailID] = useState('');
    const { forgotPasswordModal } = useSelector((state) => state.auth.modals);
    const handleClose = () => dispatch(forgotPasswordModalClose());
    const handleLoginClick = () => dispatch(loginModalOpen());
    const handleSubmit = () => {
        if (activePortal === PORTALS.TEACHER_PORTAL)
            dispatch(postTenantForgotPassword({ emailID }));
        else if (activePortal === PORTALS.STUDENT_PORTAL)
            dispatch(postStudentForgotPassword({ emailID }));
    };
    return (forgotPasswordModal && (React.createElement(Modal, { open: forgotPasswordModal, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.loginModal },
            React.createElement("div", { className: classes.closeIconBody },
                React.createElement("div", { className: classes.loginHeader }, "Forgot Password"),
                React.createElement(IconButton, { onClick: handleClose, size: "small" },
                    React.createElement(Close, null))),
            React.createElement("div", { className: classes.loginIconTab },
                React.createElement(StudentLoginIcon, { iconActive: activePortal === PORTALS.STUDENT_PORTAL, onClick: () => setActivePortal(PORTALS.STUDENT_PORTAL) }),
                React.createElement(TeacherLoginIcon, { iconActive: activePortal === PORTALS.TEACHER_PORTAL, onClick: () => setActivePortal(PORTALS.TEACHER_PORTAL) })),
            React.createElement("div", { className: classes.loginBody },
                React.createElement(Typography, null, "We'll email you a link to reset your password"),
                React.createElement(TextField, { variant: "outlined", type: "email", size: "small", fullWidth: true, label: "Email", onChange: (e) => setEmailID(e.target.value) }),
                React.createElement(Button, { variant: "contained", fullWidth: true, onClick: handleSubmit }, "Send me a password reset link")),
            React.createElement("div", { className: classes.rowAlign },
                React.createElement(Typography, null, "Remember you password?"),
                React.createElement(Button, { onClick: handleLoginClick }, "Login"))))));
};
export default ForgotPassword;
