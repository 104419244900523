import { KeyboardArrowLeft, KeyboardArrowRight, FiberManualRecord, } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useStyle } from '../../styles/style';
import { PRIMARY_COLOR } from '../../styles/colors';
const ImageSlider = ({ images }) => {
    const timerRef = useRef();
    const [imageIndex, setImageIndex] = useState(0);
    const parentWidth = 500;
    const classes = useStyle();
    const goToPrevious = () => {
        const isFirstSlide = imageIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : imageIndex - 1;
        setImageIndex(newIndex);
    };
    const goToNext = useCallback(() => {
        const isLastSlide = imageIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : imageIndex + 1;
        setImageIndex(newIndex);
    }, [imageIndex, images]);
    const goToSlide = (slideIndex) => {
        setImageIndex(slideIndex);
    };
    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            goToNext();
        }, 3000);
        return () => clearTimeout(timerRef.current);
    }, [goToNext]);
    return (React.createElement("div", { className: classes.sliderStyles },
        React.createElement("div", { className: classes.sliderLeftArrow },
            React.createElement(IconButton, { onClick: goToPrevious },
                React.createElement(KeyboardArrowLeft, { style: { fill: '#fff' } }))),
        React.createElement("div", { className: classes.sliderRightArrow },
            React.createElement(IconButton, { onClick: goToNext },
                React.createElement(KeyboardArrowRight, { style: { fill: '#fff' } }))),
        React.createElement("img", { src: `${images[imageIndex].url}`, alt: images[imageIndex].alt, className: classes.imageSlider }),
        React.createElement("div", { className: classes.dotsContainerStyles }, images.map((image, slideIndex) => (React.createElement(IconButton, { className: classes.dotStyle, key: slideIndex, onClick: () => goToSlide(slideIndex) },
            React.createElement(FiberManualRecord, { style: {
                    fill: slideIndex === imageIndex ? PRIMARY_COLOR.GREY20 : '#fff',
                }, fontSize: "small" })))))));
};
export default ImageSlider;
