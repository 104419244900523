import produce from 'immer';
import { combineReducers } from 'redux';
import { GET_ALL_META_DATA_SUCCESS } from './authContants';
import { authReducer } from './authReducer';
import { CLEAR_POST_EXAM, GET_ALL_EXAMS, GET_ALL_EXAMS_SUCCESS, GET_ALL_EXAM_SET_DETAILS, GET_EXAM_BY_ID, GET_EXAM_BY_ID_SUCCESS, GET_QUESTIONS_BY_EXAMSET, GET_QUESTIONS_BY_EXAMSET_SUCCESS, GET_QUESTIONS_ON_PAGE_LOAD, GET_TENANT_DETAIL_BY_CODE_SUCCESS, POST_CHECK_COUPON_CODE, POST_CHECK_COUPON_CODE_FAILURE, POST_CHECK_COUPON_CODE_SUCCESS, POST_PURCHASE_STATUS, POST_SEARCH_EXAM, POST_SEARCH_EXAM_SUCCESS, SAVE_EXAM_SET_DETAILS_SUCCESS, SET_QUESTION_PREVIEW, SET_SNACKBAR, UPDATE_LOADER, } from './constants';
import { studentExamReducer } from './studentExamReducer';
const initialState = {
    exams: {},
    examSet: {},
    questions: {},
    loader: { examQuestionLoader: true, couponLoader: false },
    questionPreview: {},
    snackbar: { snackbarOpen: false, snackbarType: 'success', snackbarMsg: '' },
    searchExams: {},
    coupon: {},
    tenantDetail: {},
    tenantMeta: [],
};
// eslint-disable-next-line @typescript-eslint/default-param-last
const examReducer = (state = initialState, action) => produce(state, () => {
    var _a, _b;
    switch (action.type) {
        case GET_ALL_EXAMS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examListLoader: true }) });
        }
        case GET_ALL_EXAMS_SUCCESS: {
            const exams = {};
            action.payload.forEach((exam) => {
                exams[exam.examID] = exam;
            });
            return Object.assign(Object.assign({}, state), { exams, loader: Object.assign(Object.assign({}, state.loader), { examListLoader: false }) });
        }
        case SET_SNACKBAR: {
            const { snackbarOpen, snackbarType, snackbarMsg } = action.payload;
            return Object.assign(Object.assign({}, state), { snackbar: {
                    snackbarOpen,
                    snackbarType,
                    snackbarMsg,
                } });
        }
        case GET_ALL_EXAM_SET_DETAILS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examSetDescriptionLoader: true }) });
        }
        case SAVE_EXAM_SET_DETAILS_SUCCESS: {
            const { examID, res } = action.payload;
            const newExamSet = {};
            newExamSet[examID] = res;
            return Object.assign(Object.assign({}, state), { examSet: Object.assign(Object.assign({}, state.examSet), newExamSet), loader: Object.assign(Object.assign({}, state.loader), { examSetDescriptionLoader: false, screenLoader: false }) });
        }
        case GET_QUESTIONS_BY_EXAMSET: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examQuestionLoader: true }) });
        }
        case GET_QUESTIONS_BY_EXAMSET_SUCCESS: {
            const { examSetID, res } = action.payload;
            return Object.assign(Object.assign({}, state), { questions: Object.assign(Object.assign({}, state.questions), { [examSetID]: (_b = (_a = res === null || res === void 0 ? void 0 : res[0]) === null || _a === void 0 ? void 0 : _a.questions) !== null && _b !== void 0 ? _b : [] }), loader: Object.assign(Object.assign({}, state.loader), { examQuestionLoader: false }) });
        }
        case GET_QUESTIONS_ON_PAGE_LOAD: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examQuestionLoader: true }) });
        }
        case UPDATE_LOADER: {
            const { loader, value } = action.payload;
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { [loader]: value }) });
        }
        case SET_QUESTION_PREVIEW: {
            return Object.assign(Object.assign({}, state), { questionPreview: action.payload });
        }
        case GET_EXAM_BY_ID: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examLoader: true }) });
        }
        case GET_EXAM_BY_ID_SUCCESS: {
            const exam = action.payload;
            return Object.assign(Object.assign({}, state), { exams: Object.assign(Object.assign({}, state.exams), { [exam.examID]: exam }), loader: Object.assign(Object.assign({}, state.loader), { examLoader: false }) });
        }
        case POST_SEARCH_EXAM: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { searchLoader: true }) });
        }
        case POST_SEARCH_EXAM_SUCCESS: {
            const exams = {};
            action.payload.forEach((exam) => {
                exams[exam.examID] = exam;
            });
            return Object.assign(Object.assign({}, state), { exams: Object.assign(Object.assign({}, state.exams), exams), searchExams: exams, loader: Object.assign(Object.assign({}, state.loader), { searchLoader: false }) });
        }
        case CLEAR_POST_EXAM: {
            return Object.assign(Object.assign({}, state), { searchExams: {} });
        }
        case POST_PURCHASE_STATUS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examLoader: true }) });
        }
        case POST_CHECK_COUPON_CODE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { couponLoader: true }) });
        }
        case POST_CHECK_COUPON_CODE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { couponLoader: false }), coupon: Object.assign({}, action.payload) });
        }
        case POST_CHECK_COUPON_CODE_FAILURE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { couponLoader: false }), coupon: Object.assign({}, action.payload) });
        }
        case GET_TENANT_DETAIL_BY_CODE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { tenantDetail: Object.assign({}, action.payload) });
        }
        case GET_ALL_META_DATA_SUCCESS: {
            return Object.assign(Object.assign({}, state), { tenantMeta: [...action.payload] });
        }
        default:
            return state;
    }
});
const reducer = combineReducers({
    exam: examReducer,
    auth: authReducer,
    studentExam: studentExamReducer,
});
export default reducer;
