import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import ProfileIcon from '../ProfileIcon';
const NavBar = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const handleLoginClick = () => {
        dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:LoginModalOpen' }));
        dispatch(Actions.loginModalOpen());
    };
    const handleSignUpClick = () => {
        dispatch(Actions.postPageAudit({ page: 'STUDENT PORTAL:SignUpModalOpen' }));
        dispatch(Actions.signUpModalOpen());
    };
    return (React.createElement("div", { className: classes.headerButtonDiv },
        React.createElement(Button, { onClick: () => {
                navigate('/', { replace: true });
            } }, "Home"),
        !user.loggedIn ? (React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "outlined", onClick: handleLoginClick }, "LOG IN"),
            React.createElement(Button, { variant: "contained", onClick: handleSignUpClick, disableElevation: true }, "SIGN UP"))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: () => {
                    navigate('/my-learning');
                } }, "My Learning"),
            React.createElement(ProfileIcon, null)))));
};
export default NavBar;
