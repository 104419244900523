/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../containers/images/startMyExam.png';
import { useStyle } from '../styles/style';
import NavBar from './BaseComponents/NavBar';
import NavBarCompressed from './BaseComponents/NavBarCompressed';
import Search from './BaseComponents/Search';
const Header = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const [tenantLogo, setTenantLogo] = useState();
    const { tenantMeta } = useSelector((state) => state.exam);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (tenantMeta === null || tenantMeta === void 0 ? void 0 : tenantMeta.length) {
            console.log((_d = (_c = (_b = (_a = tenantMeta.filter((meta) => meta.metaName === 'TENANT_LOGO')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.TenantMetaInfos) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.tenantMeta);
            setTenantLogo((_h = (_g = (_f = (_e = tenantMeta.filter((meta) => meta.metaName === 'TENANT_LOGO')) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.TenantMetaInfos) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.tenantMeta);
        }
    }, [tenantMeta]);
    return (React.createElement("div", { className: classes.header },
        React.createElement("img", { src: Logo, alt: "logo", className: classes.logo, onClick: () => {
                navigate('/', { replace: true });
            } }),
        React.createElement("div", { className: classes.rowAlign, style: {
                width: '-webkit-fill-available',
                justifyContent: 'flex-end',
                marginLeft: '20rem',
            } },
            !!(tenantMeta === null || tenantMeta === void 0 ? void 0 : tenantMeta.length) && (React.createElement("img", { alt: "logo", src: tenantLogo, style: { height: '2.75rem', marginRight: '12rem' } })),
            React.createElement(Search, null)),
        React.createElement(NavBar, null),
        React.createElement(NavBarCompressed, null)));
};
export default Header;
