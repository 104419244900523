/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearSearchExam, postSearchExam } from '../../store/actions';
import { PRIMARY_COLOR } from '../../styles/colors';
import { useStyle } from '../../styles/style';
const Search = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyle();
    const { loader } = useSelector((state) => state.exam);
    const [search, setSearch] = useState('');
    const handleSearch = () => {
        if (search)
            dispatch(postSearchExam({ search }));
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && search) {
            navigate('/');
            dispatch(postSearchExam({ search }));
        }
    };
    useEffect(() => {
        if (!search)
            dispatch(clearSearchExam());
    }, [search]);
    return (React.createElement(TextField, { placeholder: "Search", size: "small", onChange: (e) => setSearch(e.target.value), fullWidth: true, onKeyDown: (e) => handleKeyDown(e), className: classes.search, InputProps: {
            endAdornment: (React.createElement(React.Fragment, null, loader.searchLoader ? (React.createElement(CircularProgress, { color: "inherit", size: 20 })) : (React.createElement(SearchIcon, { onClick: handleSearch, sx: {
                    background: PRIMARY_COLOR.PRIMARY,
                    padding: '0.2rem',
                    borderRadius: '1rem',
                    cursor: 'pointer',
                } })))),
        } }));
};
export default Search;
