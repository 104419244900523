import { zodResolver } from '@hookform/resolvers/zod';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Modal, Stack, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { PORTALS } from '../../containers/constant/constants';
import { postStudentPasswordReset, postTeacherPasswordReset, resetPasswordModalClose, } from '../../store/actions';
import { useStyle } from '../../styles/style';
const ResetPassword = () => {
    var _a, _b, _c;
    const classes = useStyle();
    const dispatch = useDispatch();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { resetPasswordModal } = useSelector((state) => state.auth.modals);
    const { user } = useSelector((state) => state.auth);
    const passwordRegex = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).{6,}$/);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClose = () => {
        dispatch(resetPasswordModalClose());
    };
    const handleSubmitButton = (data) => {
        var _a;
        if (user.userType === PORTALS.STUDENT_PORTAL)
            dispatch(postStudentPasswordReset(Object.assign(Object.assign({}, data), { userID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId })));
        else
            dispatch(postTeacherPasswordReset(Object.assign(Object.assign({}, data), { userID: user.tenantID })));
    };
    const formValidation = z
        .object({
        oldPassword: z.string().nonempty('Old Password is required'),
        newPassword: z
            .string()
            .regex(passwordRegex, 'Password should match following criteria.'),
        confirmPassword: z.string(),
    })
        .refine((data) => data.newPassword === data.confirmPassword, {
        message: "Passwords don't match",
        path: ['confirmPassword'],
    });
    const loginForm = useForm({
        defaultValues: { oldPassword: '', newPassword: '', confirmPassword: '' },
        resolver: zodResolver(formValidation),
        mode: 'all',
    });
    const { register, handleSubmit, formState: { errors }, reset, } = loginForm;
    useEffect(() => {
        reset();
    }, []);
    return (resetPasswordModal && (React.createElement(Modal, { open: resetPasswordModal, onClose: handleClose, disableScrollLock: true },
        React.createElement(Box, { className: classes.loginModal },
            React.createElement("div", { className: classes.closeIconBody },
                React.createElement("div", { className: classes.loginHeader }, "Reset Password"),
                React.createElement(IconButton, { onClick: handleClose, size: "small" },
                    React.createElement(Close, null))),
            React.createElement(Stack, { spacing: 2, direction: "column" },
                React.createElement(TextField, Object.assign({ variant: "outlined", fullWidth: true, size: "small", type: showOldPassword ? 'text' : 'password', label: "Old Password" }, register('oldPassword'), { error: !!errors.oldPassword, helperText: (_a = errors.oldPassword) === null || _a === void 0 ? void 0 : _a.message, InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowOldPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showOldPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                    } })),
                React.createElement(TextField, Object.assign({ variant: "outlined", fullWidth: true, size: "small", label: "New Password", type: showNewPassword ? 'text' : 'password' }, register('newPassword'), { error: !!errors.newPassword, helperText: (_b = errors.newPassword) === null || _b === void 0 ? void 0 : _b.message, InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowNewPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showNewPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                    } })),
                errors.newPassword ? (React.createElement("ul", { className: classes.passwordList },
                    React.createElement("li", null, "Should have minimum 6 characters."),
                    React.createElement("li", null, "Should have both capital and small alphabet."),
                    React.createElement("li", null, "Should have a numeric character."),
                    React.createElement("li", null, "Should have minimum 1 special character (#?!@$%^&*-)."))) : (React.createElement(React.Fragment, null)),
                React.createElement(TextField, Object.assign({ variant: "outlined", fullWidth: true, size: "small", label: "Confirm Password", type: showConfirmPassword ? 'text' : 'password' }, register('confirmPassword'), { error: !!errors.confirmPassword, helperText: (_c = errors.confirmPassword) === null || _c === void 0 ? void 0 : _c.message, InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowConfirmPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showConfirmPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                    } })),
                React.createElement(Button, { variant: "contained", onClick: handleSubmit(handleSubmitButton) }, "Reset Password"))))));
};
export default ResetPassword;
