import { produce } from 'immer';
import { FULL_SCREEN_EXIT_COUNT, GET_STUDENT_EXAM_QUESTION_CHOICE, GET_STUDENT_EXAM_QUESTION_CHOICE_SUCCESS, GET_STUDENT_EXAM_RESULT, GET_STUDENT_EXAM_RESULT_SUCCESS, MARK_QUESTION_FOR_REVIEW, POST_EXAM_AUDIT, POST_EXAM_EVERY_CHOICE_CLICK, POST_EXAM_START, POST_EXAM_START_FAILURE, POST_EXAM_START_SUCCESS, POST_EXAM_SUBMIT_SUCCESS, UNMARK_QUESTION_FOR_REVIEW, } from './studentExamConstants';
const initialState = {
    markedForReview: {},
    answered: {},
    timeTaken: {},
    sequence: [],
    fullScreenExitCount: 0,
    loader: { exam: false, examContinue: false },
    activeExam: { examStarted: true, examResult: false },
    examResult: {},
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export const studentExamReducer = (state = initialState, action) => produce(state, () => {
    switch (action.type) {
        case MARK_QUESTION_FOR_REVIEW: {
            const { set, question } = action.payload;
            const { markedForReview } = state;
            let questionInSet;
            if (set in markedForReview)
                questionInSet = [...markedForReview[set], String(question)];
            else
                questionInSet = [String(question)];
            return Object.assign(Object.assign({}, state), { markedForReview: Object.assign(Object.assign({}, state.markedForReview), { [set]: questionInSet }) });
        }
        case UNMARK_QUESTION_FOR_REVIEW: {
            const { set, question } = action.payload;
            const questionMarkedForReview = state.markedForReview[set];
            const questionInSet = questionMarkedForReview.filter((r) => r !== String(question));
            return Object.assign(Object.assign({}, state), { markedForReview: Object.assign(Object.assign({}, state.markedForReview), { [set]: questionInSet }) });
        }
        case POST_EXAM_EVERY_CHOICE_CLICK: {
            console.log(action.payload);
            const { set, question, choiceID } = action.payload;
            const { answered } = state;
            let questionInSet;
            if (set in answered &&
                answered[set][question.questionID] &&
                answered[set][question.questionID] === choiceID) {
                questionInSet = answered[set];
                questionInSet = Object.fromEntries(Object.entries(questionInSet).filter(([key]) => key !== String(question.questionID)));
            }
            else if (set in answered) {
                questionInSet = Object.assign(Object.assign({}, answered[set]), { [question.questionID]: choiceID });
            }
            else
                questionInSet = { [question.questionID]: choiceID };
            return Object.assign(Object.assign({}, state), { answered: Object.assign(Object.assign({}, state.answered), { [set]: questionInSet }) });
        }
        case POST_EXAM_AUDIT: {
            const question = action.payload;
            const { sequence } = state;
            let lastQuestion;
            if (sequence === null || sequence === void 0 ? void 0 : sequence.length) {
                lastQuestion = sequence[sequence.length - 1];
                lastQuestion = Object.assign(Object.assign({}, lastQuestion), { endTime: question.startTime });
            }
            return Object.assign(Object.assign({}, state), { sequence: [
                    ...sequence.slice(0, sequence.length - 1),
                    lastQuestion,
                    question,
                ] });
        }
        case FULL_SCREEN_EXIT_COUNT: {
            return Object.assign(Object.assign({}, state), { fullScreenExitCount: state.fullScreenExitCount + 1 });
        }
        case POST_EXAM_START: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { exam: true }) });
        }
        case POST_EXAM_START_SUCCESS: {
            return Object.assign(Object.assign({}, state), { activeExam: Object.assign({}, action.payload), loader: Object.assign(Object.assign({}, state.loader), { exam: false }) });
        }
        case POST_EXAM_START_FAILURE: {
            return Object.assign(Object.assign({}, state), { activeExam: Object.assign({}, action.payload), loader: Object.assign(Object.assign({}, state.loader), { exam: false }) });
        }
        case POST_EXAM_SUBMIT_SUCCESS: {
            return Object.assign(Object.assign({}, state), { activeExam: { examStarted: true }, fullScreenExitCount: 0, markedForReview: {}, answered: {}, timeTaken: {}, sequence: [] });
        }
        case GET_STUDENT_EXAM_RESULT_SUCCESS: {
            const { studentExamID, result } = action.payload;
            return Object.assign(Object.assign({}, state), { examResult: Object.assign(Object.assign({}, state.examResult), { [studentExamID]: result }), loader: Object.assign(Object.assign({}, state.loader), { examResult: false }), answered: {} });
        }
        case GET_STUDENT_EXAM_RESULT: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examResult: true }) });
        }
        case GET_STUDENT_EXAM_QUESTION_CHOICE_SUCCESS: {
            const { res, examSetID } = action.payload;
            return Object.assign(Object.assign({}, state), { answered: { [examSetID]: res.data }, activeExam: Object.assign(Object.assign(Object.assign({}, state.activeExam), res.studentExam), { examStarted: true }), loader: Object.assign(Object.assign({}, state.loader), { examContinue: false }) });
        }
        case GET_STUDENT_EXAM_QUESTION_CHOICE: {
            return Object.assign(Object.assign({}, state), { loader: Object.assign(Object.assign({}, state.loader), { examContinue: true }) });
        }
        default:
            return state;
    }
});
