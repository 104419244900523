import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { z } from 'zod';
import ContactUsLogo from '../../UIElements/Icons/ContactUsLogo';
import { useStyle } from '../../styles/style';
import EditorRTE from '../BaseComponents/EditorRTE';
import { postContactUsDetail } from '../../store/actions';
const ContactUs = () => {
    var _a, _b, _c, _d, _e;
    const classes = useStyle();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const formValidation = z.object({
        userName: z.string().nonempty('UserName is required.'),
        emailId: z.string().nonempty('EmailId is required.'),
        phoneNo: z.string().nonempty('PhoneNo is required.'),
        description: z.string().nonempty('Description is required.'),
    });
    const loginForm = useForm({
        defaultValues: {
            userName: user.firstName ? `${user.firstName} ${user.lastName}` : '',
            emailId: (_a = user.emailId) !== null && _a !== void 0 ? _a : '',
            phoneNo: (_b = user.mobileNumber) !== null && _b !== void 0 ? _b : '',
            description: '',
        },
        resolver: zodResolver(formValidation),
        mode: 'all',
    });
    const handleSubmitClick = (data) => {
        dispatch(postContactUsDetail(Object.assign({}, data)));
    };
    const { register, handleSubmit, control, formState: { errors }, } = loginForm;
    return (React.createElement("div", { className: classes.examLandingMain },
        React.createElement("div", { className: classes.contactUsBanner },
            React.createElement("div", { className: classes.contactUsHead },
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "h4" }, "Looking for something in particular?"),
                    React.createElement(Typography, { variant: "h3" }, "We're here to help."))),
            React.createElement("div", null)),
        React.createElement("div", { className: classes.contactUsBody },
            React.createElement("div", { className: classes.contactUsLogo },
                React.createElement(ContactUsLogo, null)),
            React.createElement(Stack, { spacing: 2, direction: "column", className: classes.contactUsForm },
                React.createElement(TextField, Object.assign({ label: "Name", variant: "standard", fullWidth: true, size: "small" }, register('userName'), { error: !!errors.userName, helperText: (_c = errors.userName) === null || _c === void 0 ? void 0 : _c.message })),
                React.createElement(TextField, Object.assign({ label: "E-Mail", variant: "standard", fullWidth: true, size: "small" }, register('emailId'), { error: !!errors.emailId, helperText: (_d = errors.emailId) === null || _d === void 0 ? void 0 : _d.message })),
                React.createElement(TextField, Object.assign({ label: "Phone Number", variant: "standard", fullWidth: true, size: "small" }, register('phoneNo'), { error: !!errors.phoneNo, helperText: (_e = errors.phoneNo) === null || _e === void 0 ? void 0 : _e.message })),
                React.createElement(Controller, { control: control, name: "description", render: ({ field: { onChange, value } }) => (React.createElement(EditorRTE, { disabled: false, placeholder: "What can we help you with ?", errors: errors.description, onChange: onChange, value: value })) }),
                React.createElement(Button, { variant: "contained", onClick: handleSubmit(handleSubmitClick) }, "Submit")))));
};
export default ContactUs;
