import { Typography } from '@mui/material';
import React from 'react';
import { useStyle } from '../../styles/style';
import MyLearningTab from './MyLearningTab';
const MyLearning = () => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.examLandingMain },
        React.createElement("div", { className: classes.myLearningTopContainer },
            React.createElement("div", { className: classes.myLearningTopInnerDiv },
                React.createElement(Typography, { variant: "h1" }, "My Learning"))),
        React.createElement(MyLearningTab, null)));
};
export default MyLearning;
