export const stringToBoolean = (stringValue) => {
    var _a;
    switch ((_a = stringValue === null || stringValue === void 0 ? void 0 : stringValue.toLowerCase()) === null || _a === void 0 ? void 0 : _a.trim()) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
        case null:
        case undefined:
            return false;
        default:
            return JSON.parse(stringValue);
    }
};
export const booleanToString = (boolean) => {
    switch (boolean) {
        case true:
            return 'YES';
        case false:
            return 'NO';
        default:
            return 'YES';
    }
};
export const fullScreen = () => {
    const elem = document.getElementById('root');
    if (elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } // else if (elem.webkitRequestFullscreen) {
        //   /* Safari */
        //   elem.webkitRequestFullscreen();
        // } else if (elem.msRequestFullscreen) {
        //   /* IE11 */
        //   elem.msRequestFullscreen();
        // }
    }
};
export const getSecondsToTime = (d) => {
    const seconds = Number(d);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    const hDisplay = h > 10 ? h : `0${h}`;
    const mDisplay = m > 10 ? m : `0${m}`;
    const sDisplay = s > 10 ? s : `0${s}`;
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
};
export const calculateDiscountPrice = (amount, amountUSD, coupon) => {
    const result = { amount: 0, amountUSD: 0 };
    const { discountType, discountAmount } = coupon;
    if (discountType === 'ABSOLUTE') {
        result.amount = amount - discountAmount;
        result.amountUSD = Number(Number(amountUSD - discountAmount / 80).toFixed(2));
    }
    else if (discountType === 'PERCENTAGE') {
        result.amount = amount - (amount * discountAmount) / 100;
        result.amountUSD = Number(Number(amountUSD - (amountUSD * discountAmount) / 100).toFixed(2));
    }
    return result;
};
