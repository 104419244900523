/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Paypal from '../../containers/images/paypal.png';
import PhonePe from '../../containers/images/phonepe.png';
import Logo from '../../containers/images/startMyExam.png';
import { postBuyExam, postCheckCouponCode, postPageAudit, postPaypalPaymentInitiate, } from '../../store/actions';
import { PRIMARY_COLOR } from '../../styles/colors';
import { useStyle } from '../../styles/style';
import { calculateDiscountPrice } from '../../utils/commonUtils';
const Payment = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { loader, coupon } = useSelector((state) => state.exam);
    const [couponCode, setCoupon] = useState('');
    const [price, setPrice] = useState({ amount: 0, amountUSD: 0 });
    const { examID, examName, studentID, tenantID, amount, amountUSD } = state;
    const classes = useStyle();
    const handleApplyClick = () => {
        dispatch(postCheckCouponCode({ couponCode }));
    };
    useEffect(() => {
        var _a;
        setCoupon((_a = coupon === null || coupon === void 0 ? void 0 : coupon.couponCode) !== null && _a !== void 0 ? _a : '');
        setPrice(calculateDiscountPrice(amount, amountUSD, coupon));
    }, []);
    useEffect(() => {
        setPrice(calculateDiscountPrice(amount, amountUSD, coupon));
    }, [coupon.isValid]);
    return (React.createElement("div", { className: classes.mainBody },
        React.createElement("div", { className: classes.paymentPageMain },
            React.createElement("div", { className: classes.paymentDetails },
                React.createElement("img", { src: Logo, alt: "Zonatech Digital", style: { width: '240px', marginBottom: '1rem' } }),
                React.createElement(Typography, { style: { color: PRIMARY_COLOR.GREY80 } }, "Pay StartMyExam"),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { style: {
                            borderRight: `1px solid ${PRIMARY_COLOR.GREY40}`,
                        } },
                        React.createElement("div", { className: clsx(classes.examLandingPrice, (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) ? classes.diagonalCross : '') },
                            "\u20B9 ",
                            amount),
                        (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) && (React.createElement("div", { className: classes.examLandingPrice },
                            "\u20B9 ",
                            price.amount)),
                        React.createElement(Typography, { variant: "caption" }, "(Only For Indian Student)")),
                    React.createElement("div", { style: { margin: '0 0 0 0.5rem' } },
                        React.createElement("div", { className: clsx(classes.examLandingPrice, (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) ? classes.diagonalCross : '') },
                            "$ ",
                            amountUSD),
                        (coupon === null || coupon === void 0 ? void 0 : coupon.isValid) && (React.createElement("div", { className: classes.examLandingPrice },
                            "\u20B9 ",
                            price.amountUSD)),
                        React.createElement(Typography, { variant: "caption" }, "(Students other than Indians)"))),
                React.createElement(Typography, { style: { fontSize: '1.25rem', fontWeight: 300, margin: '1rem 0' } }, examName),
                React.createElement(Typography, { variant: "button", color: "green" },
                    coupon.couponCode,
                    " coupon code is applied.")),
            React.createElement("div", { className: classes.paymentPageLogos },
                React.createElement("div", { className: classes.paymentIconLogo, onClick: () => {
                        dispatch(postPageAudit({
                            page: `STUDENT PORTAL:BuyExam-PAYPAL-${examID}`,
                        }));
                        dispatch(postPaypalPaymentInitiate({
                            examID,
                            studentID,
                            tenantID,
                            amount: coupon.isValid ? price.amountUSD : amountUSD,
                        }));
                    } },
                    React.createElement("img", { src: Paypal, alt: "Paypal", style: {
                            width: '240px',
                            mixBlendMode: 'color-burn',
                            cursor: 'pointer',
                        } }),
                    React.createElement(Typography, null, "(Students From Other than India)")),
                React.createElement("div", { className: classes.paymentIconLogo, onClick: () => {
                        dispatch(postPageAudit({
                            page: `STUDENT PORTAL:BuyExam-PHONEPE-${examID}`,
                        }));
                        dispatch(postBuyExam({
                            examID,
                            studentID,
                            tenantID,
                            amount: coupon.isValid ? price.amount : amount,
                        }));
                    } },
                    React.createElement("img", { src: PhonePe, alt: "PhonePe", style: {
                            width: '240px',
                            mixBlendMode: 'color-burn',
                        } }),
                    React.createElement(Typography, null, "(For Indian Students)"))))));
};
export default Payment;
