/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useSelector } from 'react-redux';
import Loader from '../../UIElements/Icons/Loader';
import { useStyle } from '../../styles/style';
import ImageSlider from '../BaseComponents/ImageSlider';
import ExamList from './ExamList';
import TrendingExams from './TrendingExams';
import { SliderImages } from './SliderImages';
import TeacherBenefits from './TeacherBenefits';
const images = [
    {
        url: SliderImages[0],
        alt: '2',
    },
    {
        url: SliderImages[1],
        alt: '1',
    },
    {
        url: SliderImages[2],
        alt: '3',
    },
    {
        url: SliderImages[3],
        alt: '4',
    },
    {
        url: SliderImages[4],
        alt: '5',
    },
];
const LandingPage = () => {
    const exams = useSelector((state) => state.exam.exams);
    const { examListLoader: loader } = useSelector((state) => state.exam.loader);
    const classes = useStyle();
    return (React.createElement("div", { className: classes.mainBody },
        React.createElement("div", { className: classes.rowAlign },
            React.createElement("div", { style: { flex: '70%', marginRight: '1rem' } },
                React.createElement(ImageSlider, { images: images })),
            React.createElement("div", { style: { flex: '30%' } },
                React.createElement(TeacherBenefits, null))),
        React.createElement("div", { className: classes.landingMain },
            React.createElement("div", { className: classes.landingExamDetails },
                React.createElement(Typography, { variant: "h2", className: classes.headerUnderline }, "Exams Curated"),
                React.createElement("div", { className: clsx(classes.landingExamDetailMain, loader || !exams.length ? classes.center : null) }, loader ? (React.createElement(Loader, { loaderClass: classes.loader })) : (React.createElement(ExamList, { exams: exams })))),
            React.createElement("div", { className: classes.trendingExamsMain }, loader ? (React.createElement(React.Fragment, null,
                React.createElement(Skeleton, { height: 320, width: 220 }),
                React.createElement(Skeleton, { height: 320, width: 220 }),
                React.createElement(Skeleton, { height: 320, width: 220 }),
                React.createElement(Skeleton, { height: 320, width: 220 }))) : (React.createElement(TrendingExams, { exams: exams }))))));
};
export default LandingPage;
