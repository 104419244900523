import { axiosInstance, handleApiResponse, handleApiResponseOther, } from './networkUtils';
const BASE_URL = `${process.env.REACT_APP_TEACHER_BACKEND_URL}/api`;
const BASE_URL_API = process.env.REACT_APP_TEACHER_BACKEND_URL;
const IP_URL = 'https://geolocation-db.com/json/';
const STUDENT_URL_API = `${process.env.REACT_APP_STUDENT_BACKEND_URL}/api`;
const getAllExams = () => axiosInstance
    .get(`${BASE_URL}/Exam`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getExamById = (payload) => axiosInstance
    .get(`${BASE_URL}/Exam/${payload.examID}`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getAllExamSetDetails = (payload) => axiosInstance
    .get(`${BASE_URL}/Exam/${payload.examID}/Examset`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getQuestionsByExamSet = (payload) => axiosInstance
    .get(`${BASE_URL}/Exam/Examset/${payload.examSetID}/questions?tenantId=${payload.tenantID}`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentLogin = (payload) => axiosInstance
    .post(`${BASE_URL}/Student/login`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTeacherLogin = (payload) => axiosInstance
    .post(`${BASE_URL}/Login`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTeacherSignUp = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentSignUp = (payload) => axiosInstance
    .post(`${BASE_URL}/Student`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTeacherPasswordReset = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant/passwordreset`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentPasswordReset = (payload) => axiosInstance
    .post(`${BASE_URL}/Student/passwordreset`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamAudit = (payload) => axiosInstance
    .post(`${BASE_URL_API}/exam/${payload.examID}/audit`, payload.payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamStart = (payload) => axiosInstance
    .post(`${BASE_URL}/Student/examHeader?examID=${payload.examID}`, payload.payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamEveryChoiceClick = (payload) => axiosInstance
    .post(`${BASE_URL}/Student/exam/${payload.examID}/detail`, payload.payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getIpGeoAddress = () => axiosInstance
    .get(`${IP_URL}`)
    .then((response) => handleApiResponseOther(response))
    .catch((error) => handleApiResponseOther(error.response));
const getStudentTakenExam = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Student/${payload.studentID}/exams`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamSubmit = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Student/Exam/${payload.studentExamID}/submit`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getStudentExamResult = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Student/${payload.studentExamID}/result`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getStudentExamQuestionChoice = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Student/Exam/${payload.studentExamID}/continue`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getExamsBySearch = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Exam/${payload.searchKey}/search`)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postExamSearchRegistry = (payload) => axiosInstance
    .post(`${BASE_URL}/ExamSearch`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTenantForgotPassword = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant/forgotPassword`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postTenantForgotPasswordVerify = (payload) => axiosInstance
    .post(`${BASE_URL}/Tenant/forgotPassword/verify`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentForgotPassword = (payload) => axiosInstance
    .post(`${BASE_URL}/Student/forgotPassword`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentForgotPasswordVerify = (payload) => axiosInstance
    .post(`${BASE_URL}/Student/forgotPassword/verify`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postPaymentDetails = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/payment/initiate`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postPaymentStatusCheck = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/payment/status-check`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentExamResultAnalysis = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Student/${payload.examID}/result-analysis`, payload.data)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postContactUsDetail = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/contactUs/save`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postPageAudit = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Page/Track`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postPaypalPaymentInitiate = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/payment/paypal-initiate`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postCheckCouponCode = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/coupon/check`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getTenantDetailByCode = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/tenant/detail`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const postStudentTenantMapping = (payload) => axiosInstance
    .post(`${STUDENT_URL_API}/Student/tenant-mapping`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
const getAllMetaInfo = (payload) => axiosInstance
    .get(`${STUDENT_URL_API}/Tenant/meta/${payload.tenantID}`, payload)
    .then((response) => handleApiResponse(response))
    .catch((error) => handleApiResponse(error.response));
export const service = {
    getAllExams,
    getExamById,
    getAllExamSetDetails,
    getQuestionsByExamSet,
    postStudentLogin,
    postTeacherLogin,
    postTeacherSignUp,
    postStudentSignUp,
    postTeacherPasswordReset,
    postStudentPasswordReset,
    postExamAudit,
    postExamStart,
    postExamEveryChoiceClick,
    postExamSubmit,
    getIpGeoAddress,
    getStudentTakenExam,
    getStudentExamResult,
    getStudentExamQuestionChoice,
    getExamsBySearch,
    postExamSearchRegistry,
    postTenantForgotPassword,
    postTenantForgotPasswordVerify,
    postStudentForgotPassword,
    postStudentForgotPasswordVerify,
    postPaymentDetails,
    postPaymentStatusCheck,
    postStudentExamResultAnalysis,
    postContactUsDetail,
    postPageAudit,
    postPaypalPaymentInitiate,
    postCheckCouponCode,
    getTenantDetailByCode,
    postStudentTenantMapping,
    getAllMetaInfo,
};
