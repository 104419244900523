/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from '@mui/material';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useStyle } from '../../styles/style';
import Box from '../BaseComponents/Box';
import SetButtons from './SetButtons';
import { TAB_CONST } from '../../containers/constant/constants';
const UpcomingExams = ({ value }) => {
    const { studentExams, upcomingExamLoader } = useSelector((state) => state.auth);
    let exams = studentExams;
    const classes = useStyle();
    if (value === TAB_CONST.upcoming_exams) {
        exams = studentExams.filter((e) => !e.exam.completed);
    }
    if (value === TAB_CONST.completed_exams) {
        exams = studentExams.filter((e) => e.exam.completed);
    }
    return (React.createElement("div", { className: classes.upcomingExam }, upcomingExamLoader ? (React.createElement(React.Fragment, null,
        React.createElement(Skeleton, { height: 320, width: "47vw", style: { marginRight: '4px' } }),
        React.createElement(Skeleton, { height: 320, width: "47vw", style: { marginRight: '4px' } }))) : (React.createElement(React.Fragment, null, exams.length ? (exams.map((e) => {
        const { examSets, exam } = e;
        return (React.createElement("div", { key: exam.examID, className: classes.displayFlex },
            React.createElement(Box, { exam: exam }),
            React.createElement(SetButtons, { examID: exam.examID, examSet: examSets })));
    })) : (React.createElement("div", { className: classes.examLandingLoaderDiv },
        React.createElement(Typography, null, "No Exams found.")))))));
};
export default UpcomingExams;
