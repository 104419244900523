/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Typography } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useStyle } from '../../styles/style';
import { useQuestionsByExamSetId } from '../../utils/hookUtils';
const ExamQuestionSidePanel = ({ examSet, activeQuestion, noOfQuestionsPerSet, handleSetActiveQuestion, onSubmitButtonClick, }) => {
    var _a, _b, _c, _d, _e;
    const classes = useStyle();
    const { markedForReview, answered } = useSelector((state) => state.studentExam);
    const questions = useQuestionsByExamSetId(examSet.examSetID);
    return (React.createElement("div", { className: classes.questionSidePanel },
        React.createElement("div", null,
            React.createElement("div", { className: classes.questionBodyHeader },
                "SET : ",
                examSet.examSetName),
            React.createElement("div", { className: clsx(classes.questionStack, classes.scrollDefault) }, [...Array.from(Array(noOfQuestionsPerSet).keys())].map((num, i) => {
                var _a, _b;
                const isQuestionMarkedForReview = (_a = markedForReview[examSet.examSetID]) === null || _a === void 0 ? void 0 : _a.includes(String(num + 1));
                const question = questions === null || questions === void 0 ? void 0 : questions[num];
                const isQuestionAnswered = (_b = answered === null || answered === void 0 ? void 0 : answered[examSet.examSetID]) === null || _b === void 0 ? void 0 : _b[question === null || question === void 0 ? void 0 : question.questionID];
                let appliedClass = 'questionNumNotMarked';
                if (isQuestionAnswered)
                    appliedClass = 'questionNumMarked';
                if (isQuestionMarkedForReview)
                    appliedClass = 'questionNumReview';
                return (React.createElement("div", { key: i, className: clsx(classes.questionNum, classes[appliedClass]), onClick: () => handleSetActiveQuestion(num + 1) }, num + 1));
            }))),
        React.createElement("div", null,
            React.createElement("div", { className: classes.submitButton },
                React.createElement(Button, { variant: "contained", disableElevation: true, onClick: () => onSubmitButtonClick(true), fullWidth: true }, "SUBMIT EXAM")),
            React.createElement("div", { className: classes.questionMarkingScheme },
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { className: clsx(classes.questionNumNotMarked, classes.circle) }),
                    React.createElement(Typography, null,
                        "Unattempted (",
                        noOfQuestionsPerSet -
                            new Set([
                                ...((_b = Object.keys((_a = answered === null || answered === void 0 ? void 0 : answered[examSet === null || examSet === void 0 ? void 0 : examSet.examSetID]) !== null && _a !== void 0 ? _a : {})) !== null && _b !== void 0 ? _b : []),
                            ]).size,
                        ")")),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { className: clsx(classes.questionNumMarked, classes.circle) }),
                    React.createElement(Typography, null,
                        "Attempted (",
                        Object.keys(answered).length
                            ? (_c = Object.keys(answered[examSet.examSetID])) === null || _c === void 0 ? void 0 : _c.length
                            : 0,
                        ")")),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { className: clsx(classes.questionNumReview, classes.circle) }),
                    React.createElement(Typography, null,
                        "Marked for review (",
                        ((_d = Object.keys(markedForReview)) === null || _d === void 0 ? void 0 : _d.length)
                            ? (_e = markedForReview[examSet.examSetID]) === null || _e === void 0 ? void 0 : _e.length
                            : 0,
                        ")"))))));
};
export default ExamQuestionSidePanel;
