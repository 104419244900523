import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Stack, TextField, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { postStudentForgotPasswordVerify } from '../../store/actions';
import { useStyle } from '../../styles/style';
const ForgotPassword = () => {
    var _a, _b;
    const [searchParams, setSearchParams] = useSearchParams();
    const requestid = searchParams.get('requestid');
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const passwordRegex = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).{6,}$/);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmitButton = (data) => {
        dispatch(postStudentForgotPasswordVerify({
            requestId: requestid,
            password: data.newPassword,
            navigate,
        }));
    };
    const formValidation = z
        .object({
        newPassword: z
            .string()
            .regex(passwordRegex, 'Password should match following criteria.'),
        confirmPassword: z.string(),
    })
        .refine((data) => data.newPassword === data.confirmPassword, {
        message: "Passwords don't match",
        path: ['confirmPassword'],
    });
    const loginForm = useForm({
        defaultValues: { newPassword: '', confirmPassword: '' },
        resolver: zodResolver(formValidation),
        mode: 'all',
    });
    const { register, handleSubmit, formState: { errors }, reset, } = loginForm;
    useEffect(() => {
        reset();
    }, []);
    return (React.createElement("div", { className: classes.forgotPasswordRequest },
        React.createElement(Stack, { spacing: 2, direction: "column", className: classes.forgotPasswordRequestBox },
            React.createElement(Typography, { variant: "h3" }, "Please select a new password"),
            React.createElement(TextField, Object.assign({ variant: "outlined", fullWidth: true, size: "small", label: "New Password", type: showNewPassword ? 'text' : 'password' }, register('newPassword'), { error: !!errors.newPassword, helperText: (_a = errors.newPassword) === null || _a === void 0 ? void 0 : _a.message, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowNewPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showNewPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                } })),
            errors.newPassword ? (React.createElement("ul", { className: classes.passwordList },
                React.createElement("li", null, "Should have minimum 6 characters."),
                React.createElement("li", null, "Should have both capital and small alphabet."),
                React.createElement("li", null, "Should have a numeric character."),
                React.createElement("li", null, "Should have minimum 1 special character (#?!@$%^&*-)."))) : (React.createElement(React.Fragment, null)),
            React.createElement(TextField, Object.assign({ variant: "outlined", fullWidth: true, size: "small", label: "Confirm Password", type: showConfirmPassword ? 'text' : 'password' }, register('confirmPassword'), { error: !!errors.confirmPassword, helperText: (_b = errors.confirmPassword) === null || _b === void 0 ? void 0 : _b.message, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: () => setShowConfirmPassword((prev) => !prev), onMouseDown: handleMouseDownPassword, edge: "end" }, showConfirmPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                } })),
            React.createElement(Button, { variant: "contained", onClick: handleSubmit(handleSubmitButton) }, "Confirm Password"))));
};
export default ForgotPassword;
