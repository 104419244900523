import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useStyle } from '../../styles/style';
import { getSecondsToTime } from '../../utils/commonUtils';
import ResultPieChart from '../BaseComponents/ResultPieChart';
import { useGetExamById } from '../../utils/hookUtils';
const ResultAnalysis = () => {
    var _a;
    const classes = useStyle();
    const { examID } = useParams();
    const { studentExamResultAnalysisLoader, studentExamResultAnalysis } = useSelector((state) => state.auth);
    const exam = useGetExamById(examID !== null && examID !== void 0 ? examID : '');
    const studentExamResult = studentExamResultAnalysis[examID !== null && examID !== void 0 ? examID : ''];
    const rows = (_a = Object.keys(studentExamResult !== null && studentExamResult !== void 0 ? studentExamResult : {})) === null || _a === void 0 ? void 0 : _a.map((examSetID) => studentExamResult === null || studentExamResult === void 0 ? void 0 : studentExamResult[examSetID]);
    return (React.createElement("div", { className: classes.resultAnalysis },
        React.createElement("div", { className: classes.resultAnalysisHeader },
            React.createElement(Typography, null,
                "Exam : ",
                exam.examName),
            React.createElement(Typography, null,
                "Marks for correct answer : ",
                exam.correctAnswerMark),
            React.createElement(Typography, null,
                "Marks for wrong answer : ",
                exam.negativeAnswerMark),
            React.createElement(Typography, null,
                "Time for each set: ",
                getSecondsToTime(exam.durationInMinute * 60))),
        React.createElement("div", { className: classes.resultAnalysisTM },
            React.createElement(Typography, { variant: "body2", sx: { marginBottom: '12px' } }, "Time Management"),
            React.createElement(TableContainer, null,
                React.createElement(Table, { sx: {
                        minWidth: 650,
                        '& .MuiTableCell-root': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                    }, size: "small", "aria-label": "a dense table" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: classes.resultAnalysisTableHeader },
                            React.createElement(TableCell, null, "Exam Set"),
                            React.createElement(TableCell, { align: "center" }, "Total Questions"),
                            React.createElement(TableCell, { align: "center" }, "Attempted Questions"),
                            React.createElement(TableCell, { align: "center" }, "Correct Answers"),
                            React.createElement(TableCell, { align: "center" }, "Incorrect Questions"),
                            React.createElement(TableCell, { align: "center" }, "Marks Obtained"),
                            React.createElement(TableCell, { align: "center" }, "Percentage"),
                            React.createElement(TableCell, { align: "center" }, "Time Taken"))),
                    React.createElement(TableBody, null, rows === null || rows === void 0 ? void 0 : rows.map((row) => (React.createElement(TableRow, { key: row.name, className: classes.resultAnalysisTableRow, sx: {
                            '&:last-child td, &:last-child th': { border: 0 },
                        } },
                        React.createElement(TableCell, { component: "th", scope: "row" }, row['ExamSet.examSetName']),
                        React.createElement(TableCell, { align: "center" }, row.totalQuestions),
                        React.createElement(TableCell, { align: "center" }, row.attemptedQuestions),
                        React.createElement(TableCell, { align: "center", sx: {
                                color: '#42b06e',
                            } }, row.correctAnswers),
                        React.createElement(TableCell, { align: "center", sx: {
                                color: '#EC4F4F',
                            } }, row.wrongAnswers),
                        React.createElement(TableCell, { align: "center" }, row.marksObtained),
                        React.createElement(TableCell, { align: "center" },
                            row.percentage,
                            "%"),
                        React.createElement(TableCell, { align: "center" }, getSecondsToTime(row.time))))))))),
        React.createElement("div", null,
            React.createElement("div", { className: classes.resultAnalysisSA },
                React.createElement(Typography, { variant: "body2", sx: { marginBottom: '12px' } }, "Exam Set Analysis"),
                React.createElement("div", { className: classes.resultAnalysisChartMain }, rows === null || rows === void 0 ? void 0 : rows.map((row) => (React.createElement(ResultPieChart, { key: row.examID, examSet: row }))))))));
};
export default ResultAnalysis;
