import zlib from 'react-zlib-js';
import { Buffer } from 'buffer';
const MB = 1048576;
export const getSizeInMB = (object) => {
    const actualSize = Buffer.byteLength(object);
    return actualSize / MB;
};
export const getCompressedData = (object) => {
    try {
        const response = zlib.deflateSync(object).toString('base64');
        console.log(getDecompressedData(response));
        return response;
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
};
export const getDecompressedData = (object) => {
    try {
        const compressedData = Buffer.from(object, 'base64');
        const response = zlib.inflateSync(compressedData).toString();
        return response;
    }
    catch (e) {
        return undefined;
    }
};
