import { AccessAlarms } from '@mui/icons-material';
import React from 'react';
import { useStyle } from '../../styles/style';
import { EXAM_PREVIEW } from '../../containers/constant/constants';
const ExamTopBar = ({ exam, hours, minutes, seconds, type, totalMarks }) => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.questionTopBar },
        React.createElement("span", null, exam.examName),
        type === EXAM_PREVIEW.EXAM ? (React.createElement("div", { className: classes.timer },
            React.createElement(AccessAlarms, null),
            exam.durationInMinute > 60 && React.createElement("span", null,
                hours,
                " hours : "),
            minutes < 10 ? (React.createElement("span", null,
                "0",
                minutes,
                " min : ")) : (React.createElement("span", null,
                minutes,
                " min : ")),
            seconds < 10 ? (React.createElement("span", null,
                "0",
                seconds,
                " sec")) : (React.createElement("span", null,
                seconds,
                " sec")))) : (React.createElement("div", null, `Total Marks obtained: ${totalMarks}`))));
};
export default ExamTopBar;
