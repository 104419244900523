/* eslint-disable prefer-destructuring */
import { produce } from 'immer';
import { PORTALS } from '../containers/constant/constants';
import { FORGOT_PASSWORD_CLOSE, FORGOT_PASSWORD_OPEN, GET_STUDENT_EXAM_RESULT_ANALYSIS, GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS, GET_STUDENT_TAKEN_EXAM_SUCCESS, LOGIN_MODAL_CLOSE, LOGIN_MODAL_OPEN, POST_STUDENT_LOGIN_SUCCESS, POST_STUDENT_PASSWORD_RESET_SUCCESS, POST_STUDENT_SIGN_UP_SUCCESS, POST_TEACHER_SIGN_UP_SUCCESS, RESET_PASSWORD_MODAL_CLOSE, RESET_PASSWORD_MODAL_OPEN, SIGNUP_MODAL_CLOSE, SIGNUP_MODAL_OPEN, UPDATE_STUDENT_TAKEN_EXAM, USER_SIGN_OUT, } from './authContants';
const initialState = {
    modals: {
        loginModal: false,
        signUpModal: false,
        forgotPasswordModal: false,
        resetPasswordModal: false,
    },
    upcomingExamLoader: true,
    studentExamResultAnalysisLoader: false,
    user: { loggedIn: false },
    studentExams: [],
    studentExamResultAnalysis: {},
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export const authReducer = (state = initialState, action) => produce(state, () => {
    switch (action.type) {
        case USER_SIGN_OUT: {
            return Object.assign(Object.assign({}, state), initialState);
        }
        case LOGIN_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { loginModal: true, signUpModal: false, forgotPasswordModal: false }) });
        }
        case LOGIN_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { loginModal: false }) });
        }
        case SIGNUP_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { loginModal: false, signUpModal: true }) });
        }
        case SIGNUP_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { signUpModal: false }) });
        }
        case RESET_PASSWORD_MODAL_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { resetPasswordModal: true }) });
        }
        case RESET_PASSWORD_MODAL_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { resetPasswordModal: false }) });
        }
        case POST_STUDENT_LOGIN_SUCCESS: {
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, action.payload), { loggedIn: true, userType: PORTALS.STUDENT_PORTAL }), modals: Object.assign(Object.assign({}, state.modals), { loginModal: false }) });
        }
        case POST_TEACHER_SIGN_UP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { signUpModal: false, loginModal: true }) });
        }
        case POST_STUDENT_PASSWORD_RESET_SUCCESS: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { resetPasswordModal: false }) });
        }
        case POST_STUDENT_SIGN_UP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, action.payload), { loggedIn: true, userType: PORTALS.STUDENT_PORTAL }), modals: Object.assign(Object.assign({}, state.modals), { signUpModal: false }) });
        }
        case GET_STUDENT_TAKEN_EXAM_SUCCESS: {
            return Object.assign(Object.assign({}, state), { upcomingExamLoader: false, studentExams: [...action.payload] });
        }
        case UPDATE_STUDENT_TAKEN_EXAM: {
            const { examID, examSetID, transactionID, examType } = action.payload;
            const studentExams = [...state.studentExams];
            const examIndex = studentExams.findIndex((e) => e.exam.examID === examID);
            const exam = Object.assign({}, studentExams[examIndex].exam);
            const examSets = [...studentExams[examIndex].examSets];
            const examSetIndex = examSets.findIndex((e) => e.examSetID === examSetID);
            let examSet = examSets[examSetIndex];
            examSet = Object.assign(Object.assign({}, examSet), { transactionID, examType });
            examSets.splice(examSetIndex, 1, examSet);
            studentExams.splice(examIndex, 1, { exam, examSets });
            return Object.assign(Object.assign({}, state), { studentExams: [...state.studentExams] });
        }
        case FORGOT_PASSWORD_OPEN: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { loginModal: false, forgotPasswordModal: true }) });
        }
        case FORGOT_PASSWORD_CLOSE: {
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { forgotPasswordModal: false }) });
        }
        case GET_STUDENT_EXAM_RESULT_ANALYSIS: {
            return Object.assign(Object.assign({}, state), { studentExamResultAnalysisLoader: true });
        }
        case GET_STUDENT_EXAM_RESULT_ANALYSIS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { studentExamResultAnalysisLoader: false, studentExamResultAnalysis: Object.assign(Object.assign({}, state.studentExamResultAnalysis), { [action.payload.examID]: Object.assign({}, action.payload.data) }) });
        }
        default:
            return state;
    }
});
