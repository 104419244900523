import { Logout, Reorder } from '@mui/icons-material';
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../store/actions';
import { userSignOut } from '../../store/actions';
import { useStyle } from '../../styles/style';
const NavBarCompressed = () => {
    const classes = useStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogOut = () => {
        setAnchorEl(null);
        Cookies.remove('user');
        dispatch(userSignOut());
        dispatch(Actions.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'User Logged out Successfully.',
        }));
        navigate('/', { replace: true });
    };
    const handleResetPassword = () => {
        setAnchorEl(null);
        dispatch(Actions.resetPasswordModalOpen());
    };
    const handleMyAccount = () => {
        setAnchorEl(null);
        navigate('/my-account');
    };
    const handleLoginClick = () => {
        dispatch(Actions.loginModalOpen());
    };
    const handleSignUpClick = () => {
        dispatch(Actions.signUpModalOpen());
    };
    return (React.createElement("div", { className: classes.navBarCompressedMain },
        React.createElement(IconButton, { onClick: handleClick, sx: { padding: 0 }, "aria-controls": open ? 'account-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined },
            React.createElement(Avatar, { sx: { width: 36, height: 36 } }, !user.loggedIn ? (React.createElement(Reorder, null)) : (`${user.firstName.slice(0, 1).toLocaleUpperCase()}${user.lastName
                .slice(0, 1)
                .toLocaleUpperCase()}`))),
        React.createElement(Menu, { anchorEl: anchorEl, id: "account-menu", open: open, onClose: handleClose, onClick: handleClose, disableScrollLock: true, PaperProps: {
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 1,
                    },
                },
            }, transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } }, user.loggedIn ? (React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { onClick: () => {
                    navigate('/my-learning');
                } }, "My Learning"),
            React.createElement(MenuItem, { onClick: handleMyAccount }, "My Account"),
            React.createElement(MenuItem, { onClick: handleResetPassword }, "Reset My Password"),
            React.createElement(MenuItem, { onClick: handleLogOut },
                React.createElement(ListItemIcon, null,
                    React.createElement(Logout, { fontSize: "small" })),
                "Logout"))) : (React.createElement(React.Fragment, null,
            React.createElement(MenuItem, { onClick: () => {
                    navigate('/', { replace: true });
                } }, "HOME"),
            React.createElement(MenuItem, { onClick: handleLoginClick }, "LOG IN"),
            React.createElement(MenuItem, { onClick: handleSignUpClick }, "SIGN UP"))))));
};
export default NavBarCompressed;
