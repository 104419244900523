import { Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { useStyle } from '../../styles/style';
const EditorRTE = ({ errors, onChange, value, disabled, placeholder }) => {
    const classes = useStyle();
    return (React.createElement(React.Fragment, null,
        React.createElement(Editor, { value: value, disabled: disabled, init: {
                plugins: 'charmap codesample autoresize image lists textcolor colorpicker imagetools',
                toolbar: ' bold italic underline strikethrough | image align lineheight | numlist bullist indent outdent | codesample charmap | fontfamily fontsize | forecolor backcolor superscript subscript',
                menubar: false,
                branding: false,
                elementpath: false,
                height: 150,
                convert_newlines_to_brs: true,
                force_p_newlines: false,
                force_br_newlines: true,
                placeholder,
                codesample_languages: [
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'CSS', value: 'css' },
                    { text: 'PHP', value: 'php' },
                    { text: 'Ruby', value: 'ruby' },
                    { text: 'Python', value: 'python' },
                    { text: 'Java', value: 'java' },
                    { text: 'C', value: 'c' },
                    { text: 'C#', value: 'csharp' },
                    { text: 'C++', value: 'cpp' },
                ],
            }, onBlur: (e) => {
                onChange(e.target.getContent());
            } }),
        errors && (React.createElement(Typography, { variant: "caption", className: classes.error }, errors.message))));
};
export default EditorRTE;
