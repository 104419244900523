import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loader from '../../../UIElements/Icons/Loader';
import { EXAM_PREVIEW } from '../../../containers/constant/constants';
import * as Actions from '../../../store/actions';
import { useStyle } from '../../../styles/style';
import ExamBody from '../ExamBody';
import ExamTopBar from '../ExamTopBar';
import ResultPreview from './ResultPreview';
import ResultSidePanel from './ResultSidePanel';
const Result = () => {
    var _a, _b, _c, _d, _e;
    const location = useLocation();
    const dispatch = useDispatch();
    const { examID, examSetID, transactionID } = location.state;
    const [activeQuestion, setActiveQuestion] = useState(1);
    const { answered, loader, examResult } = useSelector((state) => state.studentExam);
    const { loader: questionLoader } = useSelector((state) => state.exam);
    const exam = (_a = useSelector((state) => state.exam.exams)) === null || _a === void 0 ? void 0 : _a[examID];
    const examSets = (_b = useSelector((state) => state.exam.examSet)) === null || _b === void 0 ? void 0 : _b[examID];
    const examSet = (_c = examSets === null || examSets === void 0 ? void 0 : examSets.filter((set) => set.examSetID === examSetID)) === null || _c === void 0 ? void 0 : _c[0];
    const questions = useSelector((state) => state.exam.questions);
    const question = (_d = questions === null || questions === void 0 ? void 0 : questions[examSetID]) === null || _d === void 0 ? void 0 : _d[activeQuestion - 1];
    const { noOfQuestionsPerSet } = exam;
    const { data: result, totalMarks } = (_e = examResult[transactionID]) !== null && _e !== void 0 ? _e : {
        data: {},
        totalMarks: 0,
    };
    const classes = useStyle();
    useEffect(() => {
        dispatch(Actions.getQuestionPageLoad({
            params: { examID, examSetID },
        }));
    }, []);
    const handleNextClick = () => {
        setActiveQuestion((prev) => prev + 1);
    };
    const handlePreviousClick = () => {
        setActiveQuestion((prev) => prev - 1);
    };
    const handleSetActiveQuestion = (newQuestion) => {
        setActiveQuestion(newQuestion);
    };
    return (React.createElement("div", { className: classes.resultMain }, loader.examResult || questionLoader.examQuestionLoader ? (React.createElement(Loader, { loaderClass: classes.loader, divClass: classes.resultLoader })) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.questionParent },
            React.createElement("div", { className: classes.examQuestionMain },
                React.createElement(ExamTopBar, { exam: exam, hours: 0, minutes: 0, seconds: 0, type: EXAM_PREVIEW.RESULT, totalMarks: totalMarks }),
                React.createElement(ExamBody, { activeQuestion: activeQuestion, question: question, answered: answered, examSetID: examSetID, noOfQuestionsPerSet: noOfQuestionsPerSet, handleChoiceClick: {}, handleNextClick: handleNextClick, handlePreviousClick: handlePreviousClick, questionMarkedForReview: {}, handleReviewClick: {}, type: EXAM_PREVIEW.RESULT })),
            React.createElement(ResultSidePanel, { examSet: examSet, activeQuestion: activeQuestion, noOfQuestionsPerSet: noOfQuestionsPerSet, handleSetActiveQuestion: handleSetActiveQuestion, result: result })),
        React.createElement(ResultPreview, { question: question, result: result })))));
};
export default Result;
