import React from 'react';
import { Link } from 'react-router-dom';
import { useStyle } from '../../styles/style';
import Box from '../BaseComponents/Box';
const TrendingExams = ({ exams }) => {
    const classes = useStyle();
    // return Object.keys(exams).map((examID, index) => (
    //   <div className={classes.trendingExamBox} key={index}>
    //     <Typography>{exams[examID].examName}</Typography>
    //   </div>
    // ));
    return Object.keys(exams).map((examID, index) => {
        if (index < 6) {
            return (React.createElement(Link, { key: index, to: `${eval(`\`${examID}\``)}`, className: classes.navLink },
                React.createElement(Box, { exam: exams[examID] })));
        }
    });
};
export default TrendingExams;
