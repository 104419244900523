import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStyle } from '../../styles/style';
import UpcomingExams from './UpcomingExams';
import { TAB_CONST } from '../../containers/constant/constants';
const MyLearningTab = () => {
    const [tab, setTab] = useState('allExams');
    const [tabArray, setTabArray] = useState([]);
    const [tabPanel, setTabPanel] = useState([]);
    const classes = useStyle();
    const dispatch = useDispatch();
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };
    useEffect(() => {
        setTabArray(Object.keys(TAB_CONST).map((key) => (React.createElement(Tab, { value: key, label: TAB_CONST[key], key: key, style: { color: 'white', fontWeight: 700 } }))));
        setTabPanel(Object.keys(TAB_CONST).map((key) => (React.createElement(TabPanel, { value: key, key: key },
            React.createElement(UpcomingExams, { value: TAB_CONST[key] })))));
    }, []);
    return (React.createElement(TabContext, { value: tab },
        React.createElement(Box, { sx: {
                borderColor: 'divider',
                backgroundColor: '#101e2a',
                padding: '0 10rem',
            } },
            React.createElement(TabList, { value: tab, onChange: handleTabChange, "aria-label": "scrollable force tabs example", variant: "scrollable", scrollButtons: "auto", classes: {
                    indicator: classes.myLearningtabIndicator,
                } }, tabArray)),
        tabPanel));
};
export default MyLearningTab;
