/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import './App.css';
import CustomSnackbar from './components/BaseComponents/Snackbar';
import TopLoader from './components/BaseComponents/TopLoader';
import RouteProvider from './router';
import { getAllMetaDataSuccess, getTenantDetailByCodeSuccess, postStudentLoginSuccess, setSnackbar, } from './store/actions';
import { getDecompressedData } from './utils/compressionUtils';
function App() {
    const dispatch = useDispatch();
    let user = Cookies.get('user');
    if (user) {
        user = JSON.parse(getDecompressedData(user));
        if (user === null || user === void 0 ? void 0 : user.tenantID) {
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMsg: 'Logged In Successfully.Will be redirected to TeacherPortal in few seconds.',
            }));
            setTimeout(() => {
                var _a;
                window.location.replace((_a = process.env.REACT_APP_TEACHER_PORTAL_URL) !== null && _a !== void 0 ? _a : '');
            }, 2000);
        }
        else
            dispatch(postStudentLoginSuccess(user));
    }
    useEffect(() => {
        Cookies.set('sessionID', uuid());
        if (Cookies.get('tenant')) {
            dispatch(getTenantDetailByCodeSuccess(JSON.parse(getDecompressedData(Cookies.get('tenant')))));
            dispatch(getAllMetaDataSuccess(JSON.parse(getDecompressedData(Cookies.get('tenant-metadata')))));
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TopLoader, null),
        React.createElement(CustomSnackbar, null),
        React.createElement(RouteProvider, null)));
}
export default App;
