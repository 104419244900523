/* eslint-disable no-eval */
import { Typography } from '@mui/material';
import { clsx } from 'clsx';
import React, { useState } from 'react';
import HTMLString from 'react-html-string';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useStyle } from '../../styles/style';
import Pagination from '../BaseComponents/Pagination';
const ExamList = ({ exams }) => {
    var _a, _b;
    const classes = useStyle();
    const [currentPage, setCurrentPage] = useState(1);
    const [examPerPage, setExamPerPage] = useState(9);
    const indexOfLastExam = currentPage * examPerPage;
    const indexOfFirstExam = indexOfLastExam - examPerPage;
    const searchExams = useSelector((state) => state.exam.searchExams);
    const prevExams = Object.keys(searchExams).length ? searchExams : exams;
    const currentExams = Object.keys(prevExams)
        .slice(indexOfFirstExam, indexOfLastExam)
        .reduce((result, key) => {
        result[key] = prevExams[key];
        return result;
    }, {});
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    return ((_a = Object.keys(currentExams)) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.landingExamList }, Object.keys(currentExams).map((examID) => (React.createElement("div", { key: examID, className: classes.landingExamDetail },
            React.createElement(Link, { to: `${eval(`\`${examID}\``)}`, className: clsx(classes.blue, classes.examTitle) },
                React.createElement(Typography, { variant: "h3", sx: {
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    } }, prevExams[examID].examName)),
            React.createElement(Typography, { variant: "subtitle1", sx: {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                } },
                React.createElement(HTMLString, { html: prevExams[examID].examDescription })),
            React.createElement("div", { className: classes.examDetails },
                React.createElement(Typography, { variant: "caption" },
                    "Exam Price:",
                    ' ',
                    React.createElement(Typography, { variant: "overline" },
                        "\u20B9",
                        prevExams[examID].totalExamPrice)),
                React.createElement(Typography, { variant: "caption" },
                    "No. Of Sets:",
                    ' ',
                    React.createElement(Typography, { variant: "overline" }, prevExams[examID].noOfSets)),
                React.createElement(Typography, { variant: "caption" },
                    "No. Of Questions in each set:",
                    ' ',
                    React.createElement(Typography, { variant: "overline" }, prevExams[examID].noOfQuestionsPerSet)),
                React.createElement(Typography, { variant: "caption" },
                    "Duration:",
                    ' ',
                    React.createElement(Typography, { variant: "overline" },
                        prevExams[examID].durationInMinute,
                        " min")),
                React.createElement(Typography, { variant: "caption" },
                    "Teacher:",
                    ' ',
                    React.createElement(Typography, { variant: "overline" }, prevExams[examID].teacherName))))))),
        React.createElement(Pagination, { totalItems: (_b = Object.keys(prevExams)) === null || _b === void 0 ? void 0 : _b.length, itemsPerPage: examPerPage, setCurrentPage: handlePageChange, currentPage: currentPage }))) : (React.createElement(Typography, { variant: "body2" }, "No Exams Found"));
};
export default ExamList;
