import { Button, Checkbox, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EXAM_TYPE } from '../../containers/constant/constants';
import { postExamStart, setSnackbar } from '../../store/actions';
import { useStyle } from '../../styles/style';
import { useQuestionsByExamSetId } from '../../utils/hookUtils';
const TnC = ({ setTncSuccess, enter, examSetID, exam, type }) => {
    const [checkbox, setCheckbox] = useState(false);
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const questions = useQuestionsByExamSetId(examSetID);
    const user = useSelector((state) => state.auth.user);
    const handleCheckboxClick = () => {
        setCheckbox((prev) => !prev);
    };
    const handleProceedClick = () => {
        if (exam.noOfQuestionsPerSet !== questions.length) {
            dispatch(setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMsg: 'Questions does not match the criteria.Please contact your teacher.',
            }));
            navigate('/');
            return;
        }
        if (type === EXAM_TYPE.START_EXAM)
            dispatch(postExamStart({
                payload: {
                    examSetID,
                    tenantId: exam.tenantID,
                    startDateTime: new Date(),
                    geoLocation: '',
                    activeStatus: true,
                    createdBy: user.loginID,
                    modifiedBy: user.loginID,
                },
                examID: exam.examID,
            }));
        setTncSuccess(true);
        enter();
    };
    return (React.createElement("div", { className: classes.tncMain },
        React.createElement("div", { className: classes.tncTitle }, "Term and Conditions"),
        React.createElement(Typography, { variant: "body2" }, "Below are the Exam Terms and Conditions that are presented to all candidates during the exam registration process. During registration, candidates will click to accept all Exam Terms and Conditions. No Shows, Cancellation, and Reschedule Policy - If you do not show up for your exam, your exam fee is forfeited and you will not receive a refund."),
        React.createElement(Typography, null, "Retake Policy - If you don't pass an exam, you can take it again 14 days after the date of your exam. If you don't pass a second time, you must wait 60 days before you can take it a third time. If you don't pass the third time, you must wait a year before taking it again."),
        React.createElement(Typography, null, "- You may attempt the exam in multiple languages, but each attempt regardless of language counts toward the total permissible attempts and the waiting period between attempts still applies. - Payment is required each time you take an exam. - Attempting to circumvent this Retake Policy by registering under a different name or any other means is prohibited and will result in a denied or revoked certification. Recertification Policy - See Section 1.d of the Exam Terms and Conditions Exam Content Update Policy - See Section 1.e of the Exam Terms and Conditions"),
        React.createElement("div", { className: classes.checkboxDiv },
            React.createElement(Checkbox, { onClick: handleCheckboxClick, defaultChecked: checkbox }),
            React.createElement(Typography, null, "Please check the box to accept the terms and conditions")),
        React.createElement(Button, { disabled: !checkbox, variant: "contained", onClick: handleProceedClick }, "Proceed To Exam")));
};
export default TnC;
