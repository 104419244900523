import { Button, Slide, Typography } from '@mui/material';
import React from 'react';
import { useStyle } from '../../styles/style';
const text = [
    'You can create an unlimited number of examinations or mock tests for your students without any costs.',
    `You have complete authority by determining the number of sets,
  exam duration, and setting exam fees according to your
  preferences.`,
    `Earn 75% of the specified amount for each student who takes the
  exam.`,
    `You can get a unique link for your exam, enabling you to
  effortlessly share it with students. Leverage this link for your
  marketing strategies and promotional efforts.`,
    `Utilize our platform as a collaborative space, connecting with
  other educators to share insights and enhance your teaching
  strategies.`,
];
const TeacherBenefits = () => {
    const classes = useStyle();
    return (React.createElement(Slide, { unmountOnExit: true, direction: "left", in: true, mountOnEnter: true, timeout: 1000 },
        React.createElement("div", { className: classes.slideOverLayText },
            React.createElement(Typography, { variant: "h3", fontFamily: `SuisseWorks,Georgia,Times,'Times New Roman',serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'`, fontWeight: "500" }, "Do you have Questions of any kind of Examination? Explore the benefits:"),
            React.createElement("ul", null,
                React.createElement("li", null, "Earn money life long with one time effort."),
                React.createElement("li", null,
                    React.createElement(Typography, { variant: "body2" }, "You can create an unlimited number of examinations or mock tests for your students without any costs.")),
                React.createElement("li", null,
                    React.createElement(Typography, { variant: "body2" }, "You have complete authority by determining the number of sets, exam duration, and setting exam fees according to your preferences.")),
                React.createElement("li", null,
                    React.createElement(Typography, { variant: "body2" }, "Earn 75% of the specified amount for each student who takes the exam.")),
                React.createElement("li", null,
                    React.createElement(Typography, { variant: "body2" }, "You can get a unique link for your exam, enabling you to effortlessly share it with students. Leverage this link for your marketing strategies and promotional efforts.")),
                React.createElement("li", null,
                    React.createElement(Typography, { variant: "body2" }, "Utilize our platform as a collaborative space, connecting with other educators to share insights and enhance your teaching strategies."))),
            React.createElement("div", { className: classes.rowAlign },
                React.createElement(Typography, null, "Navigate to Teacher Portal"),
                React.createElement(Button, { onClick: () => {
                        var _a;
                        return window.location.replace((_a = process.env.REACT_APP_TEACHER_PORTAL_URL) !== null && _a !== void 0 ? _a : '');
                    } }, "Click Here")))));
};
export default TeacherBenefits;
