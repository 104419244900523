import React from 'react';
import './Loader.css';
const CouponLoader = () => (React.createElement("div", { className: "dot-spinner" },
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" }),
    React.createElement("div", { className: "dot-spinner__dot" })));
export default CouponLoader;
