import { DialogContent, DialogTitle, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStyle } from '../../styles/style';
import { useQuestionsByExamSetId } from '../../utils/hookUtils';
const TimeoutModal = ({ examSetID, open, noOfQuestionsPerSet }) => {
    var _a;
    const { answered, markedForReview } = useSelector((state) => state.studentExam);
    const questions = useQuestionsByExamSetId(examSetID);
    const [notAnswered, setNotAnswered] = useState([]);
    const [answeredArray, setAnsweredArray] = useState([]);
    useEffect(() => {
        const missing = [];
        const answeredArr = [];
        questions.forEach((question, i) => {
            var _a;
            if ((_a = answered === null || answered === void 0 ? void 0 : answered[examSetID]) === null || _a === void 0 ? void 0 : _a[question.questionID]) {
                answeredArr.push(String(i + 1));
            }
            else {
                missing.push(String(i + 1));
            }
        });
        setNotAnswered(missing);
        setAnsweredArray(answeredArr);
    }, []);
    const classes = useStyle();
    return (React.createElement("dialog", { open: open, "aria-describedby": "alert-dialog-slide-description", className: classes.submitModal },
        React.createElement(DialogTitle, null, "Exam has Timed out"),
        React.createElement(DialogContent, null,
            React.createElement("div", { className: clsx(classes.centerComp, classes.textRed) },
                React.createElement(Typography, { variant: "h2" }, "Exam will submit in few seconds.")),
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "h3" }, "Questions Answered"),
                    React.createElement("div", { className: classes.rowAlign }, answeredArray.length ? (answeredArray.map((question) => (React.createElement("div", { key: question, className: clsx(classes.questionNum, classes.questionNumMarked) }, question)))) : (React.createElement("div", { className: classes.centerComp },
                        React.createElement(Typography, null, "No Question Answered"))))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "h3" }, "Questions Marked for Review"),
                    React.createElement("div", { className: classes.rowAlign }, ((_a = markedForReview[examSetID]) === null || _a === void 0 ? void 0 : _a.length) ? (markedForReview[examSetID].map((question) => (React.createElement("div", { key: question, className: clsx(classes.questionNum, classes.questionNumReview) }, question)))) : (React.createElement("div", { className: classes.centerComp },
                        React.createElement(Typography, null, "No Question Marked for Review"))))),
                React.createElement("div", null,
                    React.createElement(Typography, { variant: "h3" }, "Question not Answered"),
                    React.createElement("div", { className: classes.rowAlign }, notAnswered.length ? (notAnswered.map((question) => (React.createElement("div", { key: question, className: clsx(classes.questionNum, classes.questionNumNotMarked) }, question)))) : (React.createElement("div", { className: classes.centerComp },
                        React.createElement(Typography, null, "No Question left to attempt.")))))))));
};
export default TimeoutModal;
