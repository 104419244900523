/* eslint-disable no-extra-boolean-cast */
import { Typography } from '@mui/material';
import React from 'react';
import { QUESTION_RESULT_TYPE } from '../../../containers/constant/constants';
import { useStyle } from '../../../styles/style';
const ResultPreview = ({ question, result }) => {
    var _a, _b;
    const classes = useStyle();
    const questionResult = result[question.questionID];
    const status = (_a = questionResult === null || questionResult === void 0 ? void 0 : questionResult.result) !== null && _a !== void 0 ? _a : QUESTION_RESULT_TYPE.NOT_ANSWERED;
    let statusClass = 'questionNumReview';
    if ((questionResult === null || questionResult === void 0 ? void 0 : questionResult.result) === QUESTION_RESULT_TYPE.CORRECT)
        statusClass = 'questionNumMarked';
    if ((questionResult === null || questionResult === void 0 ? void 0 : questionResult.result) === QUESTION_RESULT_TYPE.WRONG)
        statusClass = 'questionNumNotMarked';
    let correctAnswer;
    let selectedAnswer;
    question.choices.forEach((choice, index) => {
        if (choice.choiceID === (questionResult === null || questionResult === void 0 ? void 0 : questionResult.correctChoice))
            correctAnswer = index;
        if (choice.choiceID === (questionResult === null || questionResult === void 0 ? void 0 : questionResult.selectedChoice))
            selectedAnswer = index;
    });
    return (React.createElement("div", { className: classes.resultPreview },
        React.createElement(Typography, { variant: "h2" }, " Result"),
        React.createElement("div", { className: classes.resultBox },
            React.createElement("div", { className: classes.questionNumMarked },
                "Correct Answer",
                ' ',
                React.createElement("div", { className: classes.resultPadding }, String(correctAnswer)
                    ? String.fromCharCode(65 + correctAnswer)
                    : 'NA')),
            React.createElement("div", { className: classes[statusClass] },
                "My Answer",
                ' ',
                React.createElement("div", { className: classes.resultPadding }, selectedAnswer != null
                    ? String.fromCharCode(65 + selectedAnswer)
                    : 'NA')),
            React.createElement("div", { className: classes[statusClass] },
                "Status",
                ' ',
                React.createElement("div", { className: classes.resultPadding }, status.split('_').join(' '))),
            React.createElement("div", { className: classes[statusClass] },
                "Marks Obtained",
                React.createElement("div", { className: classes.resultPadding }, (_b = questionResult === null || questionResult === void 0 ? void 0 : questionResult.marksObtained) !== null && _b !== void 0 ? _b : 0)))));
};
export default ResultPreview;
