/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import Loader from '../../UIElements/Icons/Loader';
import { EXAM_PREVIEW } from '../../containers/constant/constants';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import { useGetExamById, useGetExamSetByExamId, useQuestionsByExamSetId, } from '../../utils/hookUtils';
import SubmitExamModal from '../MyLearning/SubmitExamModal';
import TimeoutModal from '../MyLearning/TimeoutModal';
import ExamBody from './ExamBody';
import ExamQuestionSidePanel from './ExamQuestionSidePanel';
import ExamTopBar from './ExamTopBar';
const ExamQuestion = ({ examID, examSetID, fullScreenExit }) => {
    var _a;
    const classes = useStyle();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { markedForReview, answered, loader, activeExam } = useSelector((state) => state.studentExam);
    const { transactionID, examStarted } = activeExam;
    const { exam: examLoader, examContinue } = loader;
    const [activeQuestion, setActiveQuestion] = useState(1);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [questionMarkedForReview, setQuestionMarkedForReview] = useState(false);
    const exam = useGetExamById(examID);
    const examSets = useGetExamSetByExamId(examID);
    const examSet = examSets.filter((set) => set.examSetID === examSetID)[0];
    const question = (_a = useQuestionsByExamSetId(examSetID)) === null || _a === void 0 ? void 0 : _a[activeQuestion - 1];
    const { noOfQuestionsPerSet, durationInMinute } = exam;
    const handleTimeout = () => {
        setSubmitModalOpen(false);
        setTimeoutModalOpen(true);
        const timer = setTimeout(() => {
            dispatch(Actions.postExamSubmit({
                studentExamID: transactionID,
                navigate,
                fullScreenExit,
            }));
        }, 10000);
        return () => clearTimeout(timer);
    };
    const time = new Date();
    time.setSeconds(time.getSeconds() + durationInMinute * 60);
    const { seconds, minutes, hours, start } = useTimer({
        expiryTimestamp: time,
        onExpire: handleTimeout,
    });
    useEffect(() => {
        if (!examStarted) {
            navigate('/my-learning', { replace: true });
            dispatch(Actions.setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMsg: 'Error while starting the exam please contact support.',
            }));
        }
        else {
            start();
        }
    }, [examStarted]);
    useEffect(() => {
        var _a;
        if (transactionID) {
            dispatch(Actions.postExamAudit({
                set: examSetID,
                question: question.questionID,
                examID,
                startTime: new Date(),
            }));
        }
        setQuestionMarkedForReview((_a = markedForReview[examSetID]) === null || _a === void 0 ? void 0 : _a.includes(String(activeQuestion)));
    }, [activeQuestion]);
    useEffect(() => {
        var _a;
        setQuestionMarkedForReview((_a = markedForReview[examSetID]) === null || _a === void 0 ? void 0 : _a.includes(String(activeQuestion)));
    }, [markedForReview]);
    const handleNextClick = () => {
        setActiveQuestion((prev) => prev + 1);
    };
    const handlePreviousClick = () => {
        setActiveQuestion((prev) => prev - 1);
    };
    const handleReviewClick = () => {
        if (markedForReview[examSetID] &&
            markedForReview[examSetID].includes(String(activeQuestion)))
            dispatch(Actions.unMarkQuestionForReview({
                question: activeQuestion,
                set: examSetID,
            }));
        else
            dispatch(Actions.markQuestionForReview({
                question: activeQuestion,
                set: examSetID,
            }));
    };
    const handleChoiceClick = ({ choiceID }) => {
        dispatch(Actions.postExamAudit({
            set: examSetID,
            question: question.questionID,
            choiceID,
            startTime: new Date(),
            examID,
        }));
        dispatch(Actions.postExamEveryChoiceClick({
            set: examSetID,
            question,
            choiceID,
            studentExamID: transactionID,
            examID,
        }));
    };
    const handleSetActiveQuestion = (newQuestion) => {
        setActiveQuestion(newQuestion);
    };
    const handleSubmitButton = () => {
        dispatch(Actions.postExamSubmit({
            studentExamID: transactionID,
            navigate,
            fullScreenExit,
        }));
    };
    return examLoader || examContinue ? (React.createElement(Loader, { loaderClass: classes.loader })) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.questionParent, onContextMenu: () => false },
            React.createElement("div", { className: classes.examQuestionMain },
                React.createElement(ExamTopBar, { exam: exam, hours: hours, minutes: minutes, seconds: seconds, type: EXAM_PREVIEW.EXAM, totalMarks: 0 }),
                React.createElement(ExamBody, { activeQuestion: activeQuestion, question: question, answered: answered, examSetID: examSetID, noOfQuestionsPerSet: noOfQuestionsPerSet, handleChoiceClick: handleChoiceClick, handleNextClick: handleNextClick, handlePreviousClick: handlePreviousClick, questionMarkedForReview: questionMarkedForReview, handleReviewClick: handleReviewClick, type: EXAM_PREVIEW.EXAM })),
            React.createElement(ExamQuestionSidePanel, { examSet: examSet, activeQuestion: activeQuestion, noOfQuestionsPerSet: noOfQuestionsPerSet, handleSetActiveQuestion: handleSetActiveQuestion, onSubmitButtonClick: setSubmitModalOpen })),
        submitModalOpen && (React.createElement(SubmitExamModal, { examSetID: examSetID, open: submitModalOpen, handleClose: setSubmitModalOpen, handleSubmit: handleSubmitButton, noOfQuestionsPerSet: noOfQuestionsPerSet, title: "Do you want to submit the exam." })),
        timeoutModalOpen && (React.createElement(TimeoutModal, { examSetID: examSetID, open: timeoutModalOpen, noOfQuestionsPerSet: noOfQuestionsPerSet }))));
};
export default ExamQuestion;
