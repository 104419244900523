/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@mui/material';
import { clsx } from 'clsx';
import React from 'react';
import { QUESTION_RESULT_TYPE } from '../../../containers/constant/constants';
import { useStyle } from '../../../styles/style';
import { useQuestionsByExamSetId } from '../../../utils/hookUtils';
const ResultSidePanel = ({ examSet, activeQuestion, noOfQuestionsPerSet, handleSetActiveQuestion, result, }) => {
    let correct = 0;
    let wrong = 0;
    const classes = useStyle();
    const questions = useQuestionsByExamSetId(examSet.examSetID);
    return (React.createElement("div", { className: classes.questionSidePanel },
        React.createElement("div", null,
            React.createElement("div", { className: classes.questionBodyHeader },
                "SET : ",
                examSet.examSetName),
            React.createElement("div", { className: clsx(classes.questionStack, classes.scrollDefault) }, [...Array.from(Array(noOfQuestionsPerSet).keys())].map((num, i) => {
                var _a, _b;
                const question = questions[num];
                const isQuestionWrong = ((_a = result[question.questionID]) === null || _a === void 0 ? void 0 : _a.result) ===
                    QUESTION_RESULT_TYPE.WRONG;
                const isQuestionCorrect = ((_b = result[question.questionID]) === null || _b === void 0 ? void 0 : _b.result) ===
                    QUESTION_RESULT_TYPE.CORRECT;
                let appliedClass = 'questionNumReview';
                if (isQuestionCorrect) {
                    appliedClass = 'questionNumMarked';
                    correct += 1;
                }
                if (isQuestionWrong) {
                    appliedClass = 'questionNumNotMarked';
                    wrong += 1;
                }
                return (React.createElement("div", { key: i, className: clsx(classes.questionNum, classes[appliedClass]), onClick: () => handleSetActiveQuestion(num + 1) }, num + 1));
            }))),
        React.createElement("div", null,
            React.createElement("div", { className: classes.questionMarkingScheme },
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { className: clsx(classes.questionNumNotMarked, classes.circle) }),
                    React.createElement(Typography, null,
                        "Wrong (",
                        wrong,
                        ")")),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { className: clsx(classes.questionNumMarked, classes.circle) }),
                    React.createElement(Typography, null,
                        "Correct (",
                        correct,
                        ")")),
                React.createElement("div", { className: classes.rowAlign },
                    React.createElement("div", { className: clsx(classes.questionNumReview, classes.circle) }),
                    React.createElement(Typography, null,
                        "Unattempted (",
                        noOfQuestionsPerSet - wrong - correct,
                        ")"))))));
};
export default ResultSidePanel;
