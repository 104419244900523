import { Typography } from '@mui/material';
import React from 'react';
import { useStyle } from '../../styles/style';
const TermsAndCondition = () => {
    const classes = useStyle();
    return (React.createElement("div", { className: classes.privacyPolicy },
        React.createElement(Typography, { variant: "h1", className: classes.privacyPolicyTitle }, "Terms & Condition"),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "TERMS OF USE"),
            React.createElement("div", null,
                React.createElement(Typography, null, "The present Terms of Use document is being published in accordance with the provisions of the Information Technology Act, 2000 and other applicable Rules thereunder, including but not limited to the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 ( \u201CIT Rules\u201D )."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Welcome to the STARTMYEXAM'S Exam Prep website - STARTMYEXAMsexamprep.com ( \"the Website\" ) and/or STARTMYEXAM'S Exam Prep mobile app ( \"the App\" ). The Website and the App shall hereinafter be collectively referred to as \"the Platform\" . For the purposes of this Terms of Use, accessing of the Platform together with any study material made available or uploaded therein or downloaded, embedded therefrom shall hereinafter be collectively be referred to as the \"Services\" ."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "INTRODUCTION"),
            React.createElement("div", null,
                React.createElement(Typography, null, "By using or accessing the Platform in any way, including registering on the Platform, using, viewing, sharing, embedding and/or downloading any Services, you agree to be bound by the Terms of Use set forth herein. By accepting these Terms of Use, you also accept and agree to be bound by STARTMYEXAM'S Exam Prep Policies, including but not limited to the STARTMYEXAM'S Exam Prep Privacy Policy and the STARTMYEXAM'S Exam Prep Copyright Policy."),
                React.createElement("br", null),
                React.createElement(Typography, null, "If you do not understand the present Terms of Use or do not agree with/accept any part thereof, you should immediately discontinue using or accessing the Platform. Your use and/or continued use of the Platform, as the case may be, amounts to express consent by you to the terms of this Terms of Use as well as other STARTMYEXAM'S Exam Prep Policies."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "ELIGIBILITY"),
            React.createElement("div", null,
                React.createElement(Typography, null, "By agreeing to these Terms of Use, you represent that you are legally competent to enter into an agreement and provide consent to these Terms of Use. You further represent that"),
                React.createElement("br", null),
                " ",
                React.createElement(Typography, null, "You are of sound mind."),
                React.createElement("br", null),
                React.createElement(Typography, null, "By agreeing to these Terms of Use, you represent that you are legally competent to enter into an agreement and provide consent to these Terms of Use. You further represent that"),
                React.createElement("br", null),
                React.createElement(Typography, null, "Are not prohibited from entering into a legally binding contract as per applicable laws."),
                React.createElement("br", null),
                React.createElement(Typography, null, "In case the Platform is being accessed, and the Services are being availed for the benefit of a minor, you expressly confirm that you are legally competent to provide consent on behalf of such a minor and that the minor\u2019s use of the Platform and/or the Services shall be subject to the Terms of Use."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "TEACHER POLICY"),
            React.createElement("div", null,
                React.createElement(Typography, null, "Some more rule on Teacher:"),
                React.createElement("br", null),
                React.createElement(Typography, null, "1. ZONATECHDIGITAL retains the absolute right to remove any published exam without prior notification."),
                React.createElement("br", null),
                React.createElement(Typography, null, "2. Upon each purchase, the Teacher shall receive 75% of the stipulated exam amount, subject to tax deductions in accordance with the income tax policy of the respective country."),
                React.createElement("br", null),
                React.createElement(Typography, null, "3. In the event of a student lodging a complaint against an exam, the Teacher will not receive payment for that specific student. If multiple students raise concerns about the exam, ZONATECHDIGITAL is entitled to remove the exam, and the Teacher will not receive any compensation in such instances."),
                React.createElement("br", null),
                React.createElement(Typography, null, "4. The Teacher is required to accumulate a minimum of 1000 INR for Indian teachers and 200 USD for international teachers before ZONATECHDIGITAL initiates the transfer of funds."),
                React.createElement("br", null),
                React.createElement(Typography, null, "5. Should no students make a purchase within a span of 6 months, ZONATECHDIGITAL reserves the right to remove the exam from the website."),
                React.createElement("br", null),
                React.createElement(Typography, null, "6. Upon the purchase of any exam by a student, the validity period is strictly limited to 6 months. Subsequent to the expiration of the validity period, the student will be unable to access the exam."),
                React.createElement("br", null),
                React.createElement(Typography, null, "7. In the event that more than one question is identified as copied from another website or any book, ZONATECHDIGITAL maintains the right to remove the exam from its website."),
                React.createElement("br", null),
                React.createElement(Typography, null, "8. ZONATECHDIGITAL holds right to blacklist the teacher in case of occurrence of any aforesaid points."),
                React.createElement("br", null),
                React.createElement(Typography, null, "9. The teacher is solely legally liable for copy the any of the contents (Examination, Questions, Answers) and ZONATECHDIGITAL has no role in it."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "YOUR ACCOUNT"),
            React.createElement("div", null,
                React.createElement(Typography, null, "You can become a registered user of the Platform by setting up a password-protected account with us. In order to set up such an account, you will be required to enter the details of your registered email id and password or you may login using the account details of any partner website. By setting up an account, you agree to accept any and all responsibility for any and all activities that occur under your account. By setting up the account, you further agree to the contents of the Privacy Policy."),
                React.createElement("br", null),
                React.createElement(Typography, null, "STARTMYEXAM'S Exam Prep reserves the right and sole discretion to refuse access to the Platform, terminate any account, remove or restrict any content, at any time, with or without notice to you in case we notice any illegal activity on or from account or if we have reason to believe that any information provided by you is untrue, inaccurate, outdated or incomplete."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "USER CONTENT"),
            React.createElement("div", null,
                React.createElement(Typography, null, "STARTMYEXAM'S Exam Prep provides its users with the option of creating posts and/or upload content on the Platform ( \"User Content\" ). When you submit or upload any content on the Platform, you represent and warrant that:"),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "You own all copyright in the content, or if you are not the owner, that you have permission to use the content, and that you have all the rights and permissions necessary to permit uploading, displaying, publishing, sharing, storing, hosting, reproduction, downloading, the transmission of such content."),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "The content you upload will not infringe the intellectual property rights or other rights of any person or entity, including but not limited to copyright, moral rights, trademark, patent or rights of privacy or publicity."),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "Your use of the Platform will comply with all applicable laws, rules and regulations."),
                React.createElement("br", null),
                React.createElement(Typography, null, "The content does not contain material that is harmful to children, defames or vilifies any person, people, races, religion or religious group, misleads the addressee, if any, about the origin of the content or is false or misleading in nature but may reasonably be perceived as a fact and the content is not obscene, pornographic, paedophilic, indecent, insulting or harassing including on the basis of gender, threatening, harmful, invasive of privacy including bodily privacy or publicity rights, abusive, inflammatory, published to mislead or harass any person for financial gain or to cause any injury to any person, related to or encouraging money laundering or gambling or otherwise objectionable or inconsistent with or contrary to the laws in force."),
                React.createElement("br", null),
                React.createElement(Typography, null, "The content is not misleading and deceptive and does not offer or disseminate fraudulent goods, services, schemes, or promotions."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Your use of the Platform including by way of posting of content on the Platform shall not impersonate any other person."),
                React.createElement("br", null),
                React.createElement(Typography, null, "The content does not contain material that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States or public order nor does the content contain material that causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting to any other nation."),
                React.createElement("br", null),
                React.createElement(Typography, null, "The content or information uploaded or submitted on the Platform does not contain any software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource."),
                React.createElement("br", null),
                React.createElement(Typography, null, "STARTMYEXAM'S Exam Prep reserves the right to review such content uploaded by the user and in case any content is found to be a violation of any of the Terms of Use and/or any applicable laws, STARTMYEXAM'S Exam Prep may remove any content from the Platform and/or terminate or suspend the user account in its sole discretion."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "GRIEVANCE REDRESSAL MECHANISM"),
            React.createElement("div", null,
                React.createElement(Typography, null, "If you believe that any content or information accessible on the Platform violates the Terms of Use and/or any applicable laws, please notify STARTMYEXAM'S Exam Prep\u2019s designated Grievance Officer whose contact details are as below:"),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "You could send a complaint to the Grievance Officer by email to Grievance@STARTMYEXAMs.com or by physical mail to the following address:"),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "Each complaint must specify and/or be accompanied with the relevant information/documents such as your contact details wherein your full name, address, telephone number and email address are provided so that we may contact you in case of any clarifications, or, in case any counter-request is received by the person who has uploaded the content in question; details of the violation clearly and specifically identifying and describing the violation claimed including the nature of violation and how and in what manner is the content in question violative of any of the Terms of Use and/or any applicable laws, as claimed; necessary documents in support of your claim, wherever applicable; clear identification and description of the specific location(s)/URL(s) (for each material in case of more than one material) wherein the content which is claimed to be in violation is located on the Platform. If you wish to report any material on the Platform which you believe is covered under Rule 3(2)(b) of the IT Rules, please clearly specify in the subject line of your email or in the case of physical mail, in the subject line of your complaint notice, that the complaint is under Rule 3(2)(b) of the IT Rules, 2021 in order for us to be able to categorize your complaint appropriately. You agree not to make any claims against or hold STARTMYEXAM'S Exam Prep responsible for any claims, whether privacy claims or otherwise, in relation to STARTMYEXAM'S Exam Prep\u2019s receipt, handling or review of any information or documents submitted by you pursuant to a complaint under Rule 3(2)(b) of the IT Rules."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "RESTRICTION AND TERMINATION OF USE"),
            React.createElement("div", null,
                React.createElement(Typography, null, "STARTMYEXAM'S Exam Prep may block, restrict, disable, suspend or terminate your access to all or part of the free Services offered on the Platform, at any time in STARTMYEXAM'S Exam Prep's sole discretion, without prior notice to you. Specifically, in case of prolonged inactivity, STARTMYEXAM'S Exam Prep reserves the right to disable, deactivate or terminate a user's account. If an account has been disabled or deactivated for inactivity, the user name associated with that account may be given to another user without notice to you or such other party. If you violate the Terms of Use, STARTMYEXAM'S Exam Prep may at its sole discretion, block, restrict, disable, suspend or terminate the paid Services offered on the Platform."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "INTELLECTUAL PROPERTY"),
            React.createElement("div", null,
                React.createElement(Typography, null, "The Services, including but not limited to digital content on the website i.e. text, graphics, user interface, images, video interface and software as well as their selection and arrangement, may belong to STARTMYEXAM'S Exam Prep or to its partners who have granted STARTMYEXAM'S Exam Prep the right to use their content and are protected to the fullest extent possible by applicable laws related to copyrights, trademarks, trade secrets and all other intellectual property and proprietary rights (collectively, \"Intellectual Property Rights\" ). Any unauthorized use of the Services may violate such laws and the Terms of Use. STARTMYEXAM'S Exam Prep reserves all its legal rights to prohibit, stop or contain any such violations."),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "You agree not to copy, republish, frame, download, transmit, modify, adapt, create derivative works based on, rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer the Platform and Services or any parts thereof or the selection and arrangement of the Platform and Services, except as expressly authorized herein. STARTMYEXAM'S Exam Prep grants you a non-exclusive, non-transferable, limited permission to access and display the web pages within this Platform, solely on your computer or any other electronic viewing device for your personal, non-commercial use of this Platform. This permission is conditional on the basis that you shall not modify, alter or illegally use the content displayed on this Platform and shall keep intact and comply with all copyright, trademark, and other proprietary notices of the Platform, if any. The rights granted to you constitute a license and not a transfer of title in any manner."),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "Use of the Platform or Services for any other purpose other than expressly granted hereunder is expressly prohibited and may result in severe civil and criminal penalties."),
                React.createElement("br", null),
                ' ',
                React.createElement(Typography, null, "In relation to any content submitted, uploaded or generated by you on the Platform, you hereby grant an unconditional, unlimited, perpetual, royalty-free, worldwide license and permission to STARTMYEXAM'S Exam Prep and its affiliates, content sharing partners to display, store, host, publish, digitally reproduce or copy, transmit, communicate to the public, modify, translate, distribute or otherwise make such content available on the Platform or in relation to the Services provided by STARTMYEXAM'S Exam Prep. You agree that such content may continue to be made available on STARTMYEXAM'S Exam Prep despite any termination or suspension of your account, if such content has been shared, stored or embedded by any other user(s). You agree not to hold STARTMYEXAM'S Exam Prep responsible for the aforesaid activities in relation to your content."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "ADVERTISEMENTS AND PROMOTIONS"),
            React.createElement("div", null,
                React.createElement(Typography, null, "You acknowledge and agree that STARTMYEXAM'S Exam Prep is not responsible or liable for any loss or damage of any kind incurred as a result of any such dealings or as the result of the presence of such non-STARTMYEXAM'S Exam Prep advertisers on the Platform."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Our links with advertisers should not be construed as an endorsement, approval or recommendation by us of the owners or operators of those linked websites, or of any information, graphics, materials, products or services referred to or contained on those linked websites, unless and to the extent stipulated to the contrary."),
                React.createElement("br", null),
                React.createElement(Typography, null, "The student's image, videos, testimonials, score cards etc can be used for marketing and promotional purposes like, but not limited to Digital ads, website, print brochure, pamphlets, print ads etc. We do not need a separate consent for this. STARTMYEXAM'S Exam Prep is not liable to compensate the user in any form against such promotional, marketing and commercial materials. STARTMYEXAM'S Exam Prep holds the exclusive rights to change this policy without any notice."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "LINKS TO THIRD PARTY WEBSITES"),
            React.createElement("div", null,
                React.createElement(Typography, null, "The Platform may include links that redirect you to other websites. The Terms of Use do not cover these third party websites. You agree that once you leave our servers, any third party websites that you go to or interact with are at your own risk. STARTMYEXAM'S Exam Prep shall not be held liable for any default, loss of function or any risk or consequence that you may be exposed to as a result of the same."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "SPAM E-MAIL AND POSTINGS"),
            React.createElement("div", null,
                React.createElement(Typography, null, "In the event your access or use of the Platform and Services is found to be spammy, misleading, malicious, annoying or containing/promoting unsolicited e-mails or network postings, STARTMYEXAM'S Exam Prep reserves its right to obtain immediate injunctive relief against you or against such use by you, in addition to all other remedies available at law or in equity. STARTMYEXAM'S Exam Prep may also opt to block, filter or delete unsolicited emails, messages or postings as per its sole discretion."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "INDEMNITY"),
            React.createElement("div", null,
                React.createElement(Typography, null, "You agree to indemnify, defend and hold STARTMYEXAM'S Exam Prep and its officers, directors, partners, employees, agents and representatives harmless from and against any and all claims, damages, losses, liabilities, costs (including reasonable legal fees) or other expenses that arise directly or indirectly out of or from"),
                React.createElement("br", null),
                React.createElement(Typography, null, "Your user content and any other content (e.g. computer viruses) that you may submit, post to or transmit through the Platform (including a third party's use of such user content (e.g. reliance on the accuracy, completeness or usefulness of your user content)."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Your access to or use of the Platform and Services (including any use by your employees, contractors or agents and all uses of your account numbers, usernames and passwords, whether or not actually or expressly authorised by you, in connection with the Platform and Services)."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Your breach of any clause of the \u2018Terms of Use\u2019."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Any allegation that while using any of the software made available on the Platform you infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party and/or any dealings between you and any third parties advertising or promoting or linked via the Platform."),
                React.createElement("br", null),
                React.createElement(Typography, null, "Your activities in connection with the Platform."),
                React.createElement("br", null),
                React.createElement(Typography, null, "This indemnity will be applicable without regard to the negligence of any party, including any indemnified person."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "DISCLAIMER"),
            React.createElement("div", null,
                React.createElement(Typography, null, "We do not represent or guarantee that this Platform will be free from errors or viruses. You acknowledge that this Website may be affected by outages, faults or delays. Such outages, faults or delays may be caused by factors, including technical difficulties with the performance or operation of our or another person\u2019s software, equipment or systems, traffic or technical problems with the Internet or infrastructure failures."),
                React.createElement("br", null),
                React.createElement(Typography, null, "We do not accept responsibility for any loss or damage, however caused (including through negligence), that you may directly or indirectly suffer in connection with your use of this Platform, nor do we accept any responsibility for any such loss arising out of your use of or reliance on information contained on or accessed through this Platform."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "PRIVACY POLICY"),
            React.createElement("div", null,
                React.createElement(Typography, null, "Your privacy is important to us. Users of our Platform should refer to our Privacy Policy, which is incorporated into this Terms of Use by reference, with respect to the disclosure, collection, storage, usage and protection of your information during the course of your interaction with the Platform."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "COPYRIGHT POLICY"),
            React.createElement("div", null,
                React.createElement(Typography, null, "STARTMYEXAM'S Exam Prep respects and recognize the intellectual property rights of others and expects users of STARTMYEXAM'S Exam Prep to do the same. Users of our Platform should refer to our Copyright Policy, which is incorporated into this Terms of Use by reference. If you believe that your content has been used on STARTMYEXAM'S Exam Prep in a way that constitutes an infringement of your copyright, please notify STARTMYEXAM'S Exam Prep\u2019s designated agent for complaints or approach us through the procedure set out under the applicable provisions of the Information Technology Act. If you have a good faith belief that any of our users has infringed your copyright, you may follow the process as specified in our Copyright Policy."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "REFUND POLICY"),
            React.createElement("div", null,
                React.createElement("ol", null,
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Submission of the examination set renders you ineligible for any refund.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "If you have attempted more than 50% of the questions, a full refund will not be applicable. Refund eligibility will be determined based on a thorough analysis of the cause.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Refund claims based on the perceived quality of the questions will not be entertained.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Refund requests are only valid if the questions do not align with the relevant examination topics and are made following discussions with the teacher and subject matter experts.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "Once your refund claim is approved, you can expect the refund within 30 days for payments made within India and within 3 months for international students.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "If you do not receive the refund within the specified policy timeframe, please contact us at support@ZONATECHDIGITAL.com.")),
                    React.createElement("li", null,
                        React.createElement(Typography, null, "We retain the right to modify or replace this policy at our discretion, without prior notice to you.")))),
            React.createElement("br", null),
            React.createElement(Typography, null, "For refund requests please email to info@startmyexam.com with Subject Line User ID and Examination name."),
            React.createElement("br", null),
            React.createElement(Typography, null,
                "For more understanding on our Refund & Cancellation Policy, visit",
                ' ',
                React.createElement("a", { href: "https://startmyexam.com/refund-policy.html" }, "https://startmyexam.com/refund-policy.html"))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "AMENDMENTS TO THE TERMS AND CONDITIONS"),
            React.createElement("div", null,
                React.createElement(Typography, null, "STARTMYEXAM'S Exam Prep may amend the Terms of Use and other STARTMYEXAM'S Exam Prep Policies published on the Platform from time to time at its sole discretion. Your continued access or use of the Platform and the Services constitutes your acceptance of the amendments. Your access and use of the Platform and Services will be subject to the most current version of the Terms of Use, Privacy Policy and Copyright Policy posted on the Platform at the time of such use. Please regularly check the Platform to view the latest version of STARTMYEXAM'S Exam Prep Policies."))),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h2", className: classes.tncHeading }, "APPLICABLE LAW AND JURISDICTION"),
            React.createElement("div", null,
                React.createElement(Typography, null, "Terms of Use shall be governed by and construed in accordance with the Laws of India. In case of any dispute, the courts in Bhubaneswar will have exclusive jurisdiction to try any such disputes to the exclusion of all other courts.")))));
};
export default TermsAndCondition;
